import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { bgBlur } from '../../../lib/cssStyles';
import Iconify from '../../../components/iconify';
import AccountPopover from './AccountPopover';

import variables from 'src/styles/variables.scss';
import useResponsive from 'src/hooks/useResponsive';
import UserPicker from './userPicker';

AdminHeader.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function AdminHeader({ onOpenNav }) {
  const isDesktop = useResponsive('up', 'lg');

  const NAV_WIDTH = 72;

  const HEADER_HEIGHT = 48;

  const StyledRoot = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    background: variables.darkGraySecondary,
    boxShadow: 'none',
    paddingBottom: '1px',
    borderBottom: '1px solid #20242E',
    marginTop: '4px',

    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
  }));

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    backgroundColor: variables.darkGraySecondary,

    minHeight: HEADER_HEIGHT + 'px !important',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 1.5, 0, 0),
    },
  }));

  const now = new Date();
  const dayOfWeekOptions = { weekday: 'long' };
  const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };

  const dayOfWeek = now.toLocaleDateString('en-US', dayOfWeekOptions);
  const formattedDate = now.toLocaleDateString('en-US', dateOptions);

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify
            icon="eva:menu-2-fill"
            sx={{
              color: variables.greenPrimary,
            }}
          />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={3} sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <UserPicker />
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ px: '1.5rem' }}>
              <Typography sx={{ fontSize: '12px', textAlign: 'right' }}>{dayOfWeek}</Typography>
              <Typography sx={{ fontSize: '12px', fontWeight: 600, textAlign: 'right' }}>{formattedDate}</Typography>
            </Box>
            <AccountPopover />
          </Box>
        </Stack>
      </StyledToolbar>
    </StyledRoot >
  );
}