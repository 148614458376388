
import variables from 'src/styles/variables.scss';

export const WidgetOverlayStyle = (type) => {
  return {
    width: "100%",
    height: "100%",
    height: 300,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    borderRadius: 2,
    opacity: 0.5,
    backgroundImage: WidgetPreviewImage(type)
  }
}

export const WidgetPreviewImage = (type) => {
  return `url(/assets/widgets/widget_${type}.png)`
}

export const WidgetChartOptions = {
  plotOptions: {
    bar: {
      columnWidth: '60%',
      borderRadius: 0,
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: variables.lightGrayPrimary,
      },
      formatter: function (value) {
        let absValue = Math.abs(value);
        let sign = value < 0 ? '-' : ''; // Check if the original value was negative
        if (absValue < 1000) {
          return sign + absValue.toFixed(2); // Reapply the negative sign if needed
        } else if (absValue < 1000000) {
          return sign + (absValue / 1000).toLocaleString('us-US', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
          }) + 'K';
        } else {
          return sign + `${(absValue / 1000000).toLocaleString('us-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}M`;
        }
      },
    },
  },
  chart: {
    height: 300,
    type: 'rangeArea',
    animations: {
      enabled: false,
    },
  },
  fill: {
    opacity: [0.5, 1, 1],
  },
  stroke: {
    curve: 'straight',
    width: [0, 3, 2],
  },
  markers: {
    hover: {
      sizeOffset: 5,
    },
  },
  legend: {
    show: true,
    position: 'bottom',
    horizontalAlign: 'left',
  },
}

export const CashflowChartOptions = {
  chart: {
    type: 'line',
    width: '100%',
    stacked: false,
    toolbar: {
      show: false
    },
  },
  grid: {
    show: true,
    borderColor: '#414758',
  },
  colors: [variables.bluePrimary, variables.greenBackground, variables.greenPrimary],
  stroke: {
    width: [2, 0, 2],
    curve: 'straight'
  },
  fill: {
    type: ['solid', 'solid', 'solid'],
  },
  markers: {
    shape: "circle",
    size: [5, 0, 5],
    colors: variables.darkGraySecondary,
    strokeWidth: 2,
    strokeColors: [variables.bluePrimary, variables.greenBackground, variables.greenPrimary],
    strokeOpacity: 1,
    fillOpacity: 1, // 0 makes the inside of the marker transparent
    hover: {
      strokeWidth: 4,
      size: 9,
    }
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'right',
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        let absValue = Math.abs(value);
        let sign = value < 0 ? '-' : ''; // Check if the original value was negative
        if (absValue < 1000) {
          return sign + absValue.toFixed(2); // Reapply the negative sign if needed
        } else if (absValue < 1000000) {
          return sign + (absValue / 1000).toLocaleString('us-US', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
          }) + 'K';
        } else {
          return sign + `${(absValue / 1000000).toLocaleString('us-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}M`;
        }
      },
      style: {
        colors: variables.lightGrayPrimary,
      },
    },
  },
};