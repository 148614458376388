// ----------------------------------------------------------------------

export default function TextField(theme) {
    const bgColor = theme.palette.background.paper
    return {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& input:-webkit-autofill' : {
                        WebkitBoxShadow: '0 0 0 1000px ' + bgColor + ' inset',
                        WebkitTextFillColor: '#ffffff',
                    }
                }
            }
        }
    };
  }
  