import React from 'react';

import { Authenticator, View, useTheme } from '@aws-amplify/ui-react';
import Router from 'src/routes';
import Logo from './logo/Logo';

import 'src/styles/AuthStyles.scss';
import { UserSettingsProvider } from 'src/hooks/useUserSettings';
import { SettingTypes } from 'src/shared/enums';

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Logo sx={{ margin: '0 auto' }} />
      </View>
    );
  }
};

const formFields = {
  signUp: {
    'custom:company_name': {
      label: 'Company name',
      placeholder: 'Enter your company name',
      isRequired: false,
      order: 1,
    }
  },
};

function AuthWrapper() {
  return (
    <Authenticator
      loginMechanisms={['email']}
      socialProviders={['google']}
      components={components}
      formFields={formFields}
      hideSignUp
    >
      <UserSettingsProvider keys={[SettingTypes.currency, SettingTypes.environment, SettingTypes.profile, SettingTypes.first_auth, SettingTypes.company_info]}>
      <Router />
      </UserSettingsProvider>
    </Authenticator>
  );
}

export default AuthWrapper;
