import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import variables from 'src/styles/variables.scss';


export default function ImportHistory({ user }) {
    const navigate = useNavigate();

    return (
        <>
            <LoadingButton
                variant="outlined"
                onClick={() => navigate('/admin/user/import-history')}
                sx={{
                    height: '42px',
                    color: variables.lightGraySecondary,
                    fontSize: '12px',
                    border: 'none',
                    fontWeight: 400,
                    '&:hover': {
                        border: 'none',
                        background: 'transparent',
                    },
                    mr: '1rem',
                }}
            >
                <img src='/assets/icons/ic_history.svg' alt="upload icon" style={{ paddingRight: '0.5rem', }} />
                Import history
            </LoadingButton>
        </>
    )
}