

import { IconButton, Menu, MenuItem, Skeleton, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTransactionsProvider } from 'src/hooks/useTransactionsProvider';
import { Currencies, TransactionsMappingFields } from 'src/shared/enums';
import { removeUnderscore } from 'src/shared/utils';
import 'src/styles/styles.scss';
import variables from 'src/styles/variables.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const TransactionTableCellStyle = (theme, header = false) => {
    return {
        border: 3,
        borderColor: theme.palette.background.paper,
        background: theme.palette.background[header ? "dark" : "paper"],
        color: "white",
        padding: 1
    }
}
const TransactionsTable = () => {
    const theme = useTheme();
    const transactionsProvider = useTransactionsProvider();
    
    const [anchorEl, setAnchorEl] = useState(null);

    const transactions = transactionsProvider?.data ?? [];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMark = () => {
        //do not change the filter, only require the data with extra parameter
        transactionsProvider.fetchData({ ...transactionsProvider.filter }, true);
        handleClose();
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {TransactionsMappingFields.map((field, index) => {
                            const fieldName = removeUnderscore(field.replace("transaction_", ""))

                            return (
                                <TableCell key={field} sx={TransactionTableCellStyle(theme, true)}>
                                    {
                                        index == 0 &&
                                        <>
                                            <IconButton
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                size='small'
                                                onClick={handleClick}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleMark}>Mark all seen</MenuItem>
                                            </Menu>
                                        </>
                                    }
                                    {fieldName}
                                </TableCell>
                            );
                        })
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    (transactionsProvider?.loading == null || transactionsProvider?.loading == true && transactions.length == 0) && <TransactionsTableLoadingRow />
                }
                {transactionsProvider?.loading !== true && transactions?.map((transaction) => {
                    const isSeen = transaction?.is_seen == 1 ? true : false;
                    const editables = ["category_key", "amount"]
                    return (
                        <TableRow key={transaction.id}>
                            {

                                TransactionsMappingFields.map((field) => {
                                    let fieldValue = transaction[field] ?? "-";

                                    if (field == "category_key")
                                        fieldValue = removeUnderscore((transaction[field] ?? "_"));

                                    if (field == "currency")
                                        fieldValue = Currencies[transaction[field]] ?? "-";

                                    if (field == "amount")
                                        fieldValue = parseFloat(fieldValue).toLocaleString('en-US');

                                    return (
                                        <TableCell key={field} sx={{ ...TransactionTableCellStyle(theme), backgroundColor: transaction?.is_seen == 1 ? "inherit" : variables.greenBackground }}>
                                            <TransactionFieldCell value={fieldValue} editable={editables.includes(field)}></TransactionFieldCell>
                                        </TableCell>
                                    );
                                })
                            }
                        </TableRow>
                    );
                })
                }

            </TableBody>
            <TableFooter>
                <TableRow>
                {
                    (transactionsProvider?.loading == null || transactionsProvider?.loading == true && (transactions.length == 0 && transactionsProvider?.filter?.page == 0)) &&
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[]}
                            count={-1}
                            rowsPerPage={transactionsProvider?.filter?.limit ?? 10}
                            page={transactionsProvider?.filter?.page ?? 0}
                            onPageChange={(ev, page) => {
                                transactionsProvider.setFilter({ ...transactionsProvider.filter, page: page })
                            }}
                        />
                }
                </TableRow>
            </TableFooter>
        </Table>

    );
}

const TransactionFieldCell = ({ value, editable = true }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    const handleClick = () => {
        if (editable == false)
            return;
        setIsEditing(true);
    };

    const handleChange = (event) => {
        setCurrentValue(event.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setIsEditing(false);
        }
    };

    return isEditing ? (
        <TextField
            value={currentValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            size="small"
            sx={{ padding: 0 }}
        />
    ) : (
        <Typography

            variant="body2"
            onClick={() => {}}
            sx={{
                cursor: editable ? "pointer" : "inherit",
                '&:hover': {
                    textDecoration: editable ? "underline" : "none"
                }
            }}
        >
            {currentValue}
        </Typography>
    );
};

const TransactionsTableLoadingRow = () => {
    const theme = useTheme();
    return (
        <TableRow>
            <TableCell sx={TransactionTableCellStyle(theme)} colSpan={TransactionsMappingFields.length}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </TableCell>
        </TableRow>)
}

export default TransactionsTable
