import { createStore } from 'redux';
import { insertOrUpdateArray } from 'src/shared/utils';

export const GlobalActionTypes = Object.freeze({
    USER_SETTINGS: "USER_SETTINGS",
    IMPERSONATED_USER_SETTINGS: "IMPERSONATED_USER_SETTINGS",
});

const initialState = {
    settings: [],
    impersonated_settings: [],
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case GlobalActionTypes.USER_SETTINGS: {
            const newState = { ...state };
            newState.settings = insertOrUpdateArray(newState.settings, action.payload);
            return newState;
        }
        case GlobalActionTypes.IMPERSONATED_USER_SETTINGS: {
            const newState = { ...state };
            newState.impersonated_settings = insertOrUpdateArray(newState.impersonated_settings, action.payload);
            return newState;
        }
        default:
            return state;
    }
}

const globals = createStore(rootReducer);

export default globals;