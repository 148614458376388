import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import clientApi from "src/client/client";
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Input, InputAdornment, MenuItem, Popover, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, styled } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { FilterLimitTypes, EnvironmentTypes, TransactionsTypes, TransactionsProviderFilter, Currencies } from "src/shared/enums";
import { useSelector } from "react-redux";
import { arrayToObject } from "src/shared/utils";
import variables from 'src/styles/variables.scss';
import 'src/styles/styles.scss';
import Iconify from "src/components/iconify";
import { useAuth } from "./useAuth";
import _ from "lodash";
import { categoriesList } from "src/utils/categoriesList";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const TransactionsContext = createContext();

export const useTransactionsProvider = () => {
  const context = useContext(TransactionsContext);
  if (!context) {
    throw new Error('useTransactionsProvider must be used within a TransactionsProviderContext.Provider');
  }
  return context;
};

export const TransactionsProvider = ({ children, selectedUser, filterConfig = {}, object = "transactions" }) => {
  const { user } = useAuth();
  const globalSettings = arrayToObject(useSelector(state => state.settings));
  const impersonatedSettings = arrayToObject(useSelector(state => state.impersonated_settings));

  const dataSettings = user.isAdmin && impersonatedSettings?.currentUser ? impersonatedSettings : globalSettings;

  const [filter, setFilter] = useState({
    user: user.isAdmin ? impersonatedSettings.currentUser?.id ?? null : null,
    searchQuery: '',
    currency: dataSettings?.currency ?? Currencies.gbp,
    environment: dataSettings?.environment ?? EnvironmentTypes.live,
    type: filterConfig?.type ?? TransactionsTypes.all,
    page: 0,
    limit: 10,
    category_key: filterConfig?.category_key ?? null,
    period: filterConfig?.period ?? null,
    object: object,
    start: filterConfig?.start ?? null,
    end: filterConfig?.end ?? moment().format('YYYY-MM-DD'),
    minAmount: filterConfig?.minAmount ?? null,
    maxAmount: filterConfig?.maxAmount ?? null,
    accounts: filterConfig?.accounts ?? null,
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchConnectors = async () => {
    const user = impersonatedSettings.currentUser?.id ?? null;
    const connectors = await clientApi().app.connectors.list({ user }).catch((err) => { });

    return connectors;
  }

  const fetchData = async (_filter, markSeen = false) => {
    setLoading(true);

    const dataFilter = { ..._filter };

    if (markSeen)
      dataFilter.seen = "sync";

    if (_filter.limit === '') {
      delete dataFilter.limit;
      delete dataFilter.page;
    }

    const result = await clientApi().transactions.list(dataFilter).catch((error) => { }).finally(() => setLoading(false));

    setData(result);
  }

  useEffect(() => {
    fetchData({ ...filter });
  }, [filter]);

  useEffect(() => {
    if (dataSettings.environment != filter.environment || dataSettings.currency != filter.currency) {
      const newFilter = { ...filter, environment: dataSettings.environment, currency: dataSettings.currency };
      setFilter(newFilter);
    }
  }, [dataSettings]);

  const deleteData = async (deleteFilter) => {
    setLoading(true);

    const response = await clientApi().transactions.remove(deleteFilter, { user: filter.user }).catch((error) => { console.log(error) });

    fetchData({ ...filter });
  }

  const updateData = async (updateFilter) => {
    setLoading(true);

    const response = await clientApi().transactions.update(updateFilter.ids, { user: filter.user,  field: updateFilter.field, old_value: updateFilter.old_value, new_value: updateFilter.new_value}).catch((error) => { console.log(error) });

    fetchData({ ...filter });

    return response;
  }

  const contextValue = {
    loading,
    data,
    filter,
    fetchConnectors,
    setFilter,
    fetchData,
    deleteData,
    updateData,
  };

  return (
    <TransactionsContext.Provider value={contextValue}>
      <TransactionsFilter loading={loading} config={filterConfig}></TransactionsFilter>
      {
        React.Children.map(children, child => {
          if (child != null)
            return React.cloneElement(child, { loading: loading });
          return null;
        })
      }
    </TransactionsContext.Provider>
  );
};

const TransactionsFilter = ({ loading, config = {} }) => {
  return (
    <Box>
      <Stack direction={"row"} justifyContent="space-between" alignItems={"center"} spacing={2} mb={2} minHeight={40}>
        <CustomSearchBar></CustomSearchBar>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <CircularProgress size={20} sx={{ display: loading ? "block" : "none" }}></CircularProgress>
          {
            Object.keys(config).includes(TransactionsProviderFilter.transaction) &&
            <FilterButtonPicker
              config={config[TransactionsProviderFilter.transaction]}
              filterField="type"
              ariaLabel={"Transaction Type"}
              options={TransactionsTypes}
            ></FilterButtonPicker>
          }
          {
            Object.keys(config).includes(TransactionsProviderFilter.limit) &&
            <FilterButtonPicker
              config={config[TransactionsProviderFilter.limit]}
              filterField={"limit"}
              ariaLabel={"Page size"}
              options={FilterLimitTypes}
            ></FilterButtonPicker>
          }
        </Stack>
      </Stack>
      <FiltersSelector></FiltersSelector>
    </Box>
  )
}

function FilterButtonPicker({ config, ariaLabel, filterField, options }) {
  const transactionsProvider = useTransactionsProvider();

  const handleChange = (event, newPeriod) => {
    if (newPeriod == null)
      return

    const newFilter = { ...transactionsProvider.filter }
    newFilter[filterField] = newPeriod
    transactionsProvider.setFilter(newFilter);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
      <ToggleButtonGroup
        color="primary"
        value={transactionsProvider.filter[filterField]}
        exclusive
        onChange={handleChange}
        aria-label={ariaLabel}
        size='small'
        sx={{
          height: '40px',
          '.MuiToggleButton-root': {
            px: '1.5rem',
            border: '1px solid rgba(145, 158, 171, 0.24) !important',
            '&.Mui-selected': {
              backgroundColor: variables.darkGrayPrimary,
              ':hover': {
                backgroundColor: variables.greenBackground,
              },
            },
          },
        }}
      >
        {
          Object.keys(options).map((type) => {
            const enabledButtons = config?.options ?? Object.keys(options);
            if (!enabledButtons.includes(type))
              return null;

            return (
              <ToggleButton
                value={options[type]}
                key={type}
                className="filter-btn"
              >
                {type}
              </ToggleButton>);
          })
        }
      </ToggleButtonGroup>
    </Box>
  );
}

function CustomSearchBar() {
  const StyledSearchbar = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    paddingLeft: '1rem',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    borderRadius: '4px',
    background: variables.darkGraySecondary,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '700px',
    },
  }));

  const { setFilter } = useTransactionsProvider();
  const [inputValue, setInputValue] = useState("");

  // Memoize the debounced function using useCallback
  const debouncedSearch = useCallback(_.debounce((query) => {
    setFilter((prevFilter) => ({ ...prevFilter, searchQuery: query }));
  }, 800), []); // Dependencies array is empty, function is created only once

  const handleSearchChange = (event) => {
    setInputValue(event.target.value); // Update local state immediately
    debouncedSearch(event.target.value); // Call the debounced function
  };

  // Cleanup the debounced function on component unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]); // Ensure debouncedSearch is stable across re-renders

  return (
    <StyledSearchbar>
      <Input
        value={inputValue}
        autoFocus
        fullWidth
        disableUnderline
        placeholder="Search by ID, category, or amount"
        startAdornment={
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{
                color: variables.lightGraySecondary,
                width: 20,
                height: 20,
              }}
            />
          </InputAdornment>
        }
        sx={{
          mr: 1,
          fontWeight: '400',
          color: variables.lightGraySecondary,
        }}
        onChange={handleSearchChange}
      />
    </StyledSearchbar>
  );
}

const formatCategory = (category) => {
  return category
    .split('_') // Split the string into words by underscore
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words back into a string, with spaces
};

function FiltersSelector() {
  const { setFilter, fetchConnectors } = useTransactionsProvider();
  const [open, setOpen] = useState(null);
  const [amountPopover, setAmountPopover] = useState(null);
  const [categoriesPopover, setCategoriesPopover] = useState(null);
  const [calendarPopover, setCalendarPopover] = useState(null);
  const [accountPopover, setAccountPopover] = useState(null);
  const categories = categoriesList;
  const amountRef = useRef(null);
  const categoryRef = useRef(null);
  const calendarRef = useRef(null);
  const accountRef = useRef(null);
  const [filters, setFilters] = useState({
    start: null,
    end: null,
    minAmount: null,
    maxAmount: null,
    category: [],
    accounts: [],
  });
  const [tempMinAmount, setTempMinAmount] = useState('');
  const [tempMaxAmount, setTempMaxAmount] = useState('');
  const [isAmountError, setIsAmountError] = useState(false);
  const [connectors, setConnectors] = useState([]);

  useEffect(() => {
    const fetchConnectorsList = async () => {
      const connectors = await fetchConnectors();
      setConnectors(connectors);
    }

    fetchConnectorsList();
  }, []);

  const accounts = connectors ? connectors?.flatMap(connector => {
    // Check if there's no error and accountId.data is an array
    if (!connector?.accountId?.error && Array.isArray(connector?.accountId?.data)) {
      // Return an array of account.id values from accountId.data
      return connector.accountId.data.map(account => account.id);
    } else {
      // Return an empty array if there's an error, to skip this connector
      return [];
    }
  }) : [];

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setAccountPopover(null);
    setCalendarPopover(null);
    setCategoriesPopover(null);
    setAmountPopover(null);
    setOpen(null);
  };

  const removeAllFilters = () => {
    setFilters({ ...filters, start: null, end: null, minAmount: null, maxAmount: null, category: [] })
    setFilter((prevFilter) => ({ ...prevFilter, start: null, end: null, minAmount: null, maxAmount: null, category_key: null }));
  }

  const handleAddAmountFilter = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      minAmount: tempMinAmount,
      maxAmount: tempMaxAmount,
    }));
    setFilter((prevFilter) => ({ ...prevFilter, minAmount: tempMinAmount, maxAmount: tempMaxAmount }));

    setTempMinAmount('');
    setTempMaxAmount('');
    setAmountPopover(null);
    setOpen(null);
  };

  const handleAddCategories = (selectedCategories) => {
    setFilters({
      ...filters,
      category: selectedCategories,
    });
    setFilter((prevFilter) => ({ ...prevFilter, category_key: selectedCategories }));
    setCategoriesPopover(null);
    setOpen(null);
  };

  const handleAddAccount = (selectedAccounts) => {
    setFilters({
      ...filters,
      accounts: selectedAccounts,
    });
    setFilter((prevFilter) => ({ ...prevFilter, accounts: selectedAccounts }));
    setAccountPopover(null);
    setOpen(null);
  };

  const handleAddCalendarFilter = (calendarStart, calendarEnd) => {
    setFilters({
      ...filters,
      start: calendarStart,
      end: calendarEnd,
    });
    setFilter((prevFilter) => ({ ...prevFilter, start: moment(calendarStart).format('YYYY-MM-DD'), end: moment(calendarEnd).format('YYYY-MM-DD') }));
    setCalendarPopover(null);
    setOpen(null);
  }

  const checkAmountError = (e) => {
    if (Number(tempMinAmount) > Number(e)) {
      setIsAmountError(true);
    } else {
      setIsAmountError(false)
    }
  }

  return (
    <>
      <Box
        sx={{
          height: '40px',
          background: variables.darkGraySecondary,
          borderRadius: '4px',
          p: '4px 8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '1rem',
        }}
      >
        <Box sx={{ display: 'flex', flexFlow: 'row', alignItems: 'center', }}>
          {(filters.start && filters.end) &&
            <FilterItem
              label="Transaction Date"
              width={'160px'}
              value={`${moment(filters.start).format('DD MMM YYYY')} - ${moment(filters.end).format('DD MMM YYYY')}`}
              onRemove={() => { setFilters({ ...filters, start: null, end: null }); setFilter((prevFilter) => ({ ...prevFilter, start: null, end: null })); }}
            />
          }
          {filters.category.length > 0 &&
            <FilterItem
              label="Category"
              width={'160px'}
              // Format each category and join with comma
              value={filters.category.map(formatCategory).join(', ')}
              onRemove={() => { setFilters({ ...filters, category: [] }); setFilter((prevFilter) => ({ ...prevFilter, category_key: null })); }}
            />
          }
          {filters.accounts.length > 0 &&
            <FilterItem
              label="Account"
              width={'160px'}
              // Format each category and join with comma
              value={filters.accounts.map(formatCategory).join(', ')}
              onRemove={() => { setFilters({ ...filters, accounts: [] }); setFilter((prevFilter) => ({ ...prevFilter, accounts: null })); }}
            />
          }
          {(filters.minAmount || filters.maxAmount) &&
            <FilterItem
              label="Amount"
              width={'100%'}
              value={`${filters.minAmount !== null ? (+filters.minAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : "0.00"
                } - ${filters.maxAmount !== null ? (+filters.maxAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : "0.00"
                }`}
              onRemove={() => { setFilters({ ...filters, minAmount: null, maxAmount: null }); setFilter((prevFilter) => ({ ...prevFilter, minAmount: null, maxAmount: null })); }}
            />
          }

          <FilterButton onClick={handleOpen} />
        </Box>
        {(filters.minAmount || filters.start || filters.category.length > 0) &&
          <RemoveAllFilterButton onClick={removeAllFilters}></RemoveAllFilterButton>
        }
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          sx: {
            width: '232px',
            left: '75px !important',
            p: '8px',
            m: '0.9rem',
            borderRadius: '4px',
            background: variables.darkGrayPopover,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box>
          <MenuItem
            ref={calendarRef}
            onClick={() => { setCalendarPopover(calendarRef.current); setAmountPopover(null); setCategoriesPopover(null); setAccountPopover(null); }}
            sx={{ height: '40px', justifyContent: 'space-between', px: '0.5rem', '&:hover': { color: variables.bluePrimary } }}
          >
            Transaction Date
            <img src="/assets/icons/ic_calendar.svg" style={{ width: '14px', height: '12px', }} alt="calendar icon" />
          </MenuItem>
          <MenuItem
            ref={amountRef}
            onClick={() => { setAmountPopover(amountRef.current); setCalendarPopover(null); setCategoriesPopover(null); setAccountPopover(null); }}
            sx={{ height: '40px', justifyContent: 'space-between', px: '0.5rem', '&:hover': { color: variables.bluePrimary } }}
          >
            Amount
            <ArrowForwardIosIcon sx={{ width: '14px', height: '14px', }} />
          </MenuItem>
          <MenuItem
            ref={categoryRef}
            onClick={() => { setCategoriesPopover(categoryRef.current); setCalendarPopover(null); setAmountPopover(null); setAccountPopover(null); }}
            sx={{ height: '40px', justifyContent: 'space-between', px: '0.5rem', '&:hover': { color: variables.bluePrimary } }}
          >
            Category
            <ArrowForwardIosIcon sx={{ width: '14px', height: '14px', }} />
          </MenuItem>
          <MenuItem
            ref={accountRef}
            onClick={() => { setAccountPopover(accountRef.current); setCalendarPopover(null); setAmountPopover(null); setCategoriesPopover(null); }}
            sx={{ height: '40px', justifyContent: 'space-between', px: '0.5rem', '&:hover': { color: variables.bluePrimary } }}
          >
            Account
            <ArrowForwardIosIcon sx={{ width: '14px', height: '14px', }} />
          </MenuItem>
        </Box>
      </Popover>

      <GenericPopover open={Boolean(categoriesPopover)} anchorEl={categoriesPopover} style={{ width: '230px', p: '1.5rem 1rem', m: '0.9rem', mt: '-3rem', }} onClose={() => setCategoriesPopover(null)}>
        <CategorySelector categories={categories} onAddCategories={handleAddCategories} />
      </GenericPopover>

      <GenericPopover open={Boolean(amountPopover)} anchorEl={amountPopover} style={{ width: '320px', p: '1.5rem 1rem', m: '0.9rem', mt: '-3rem', }} onClose={() => setAmountPopover(null)}>
        <Box sx={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography>From</Typography>
            <TextField
              value={tempMinAmount}
              placeholder="0.00"
              onChange={(e) => setTempMinAmount(e.target.value)}
              type="number"
              variant="outlined"
              size="small"
              margin="normal"
              sx={{ m: 0, width: '120px' }}
            />
          </Box>
          <Box sx={{ width: '30px', margin: '0.5rem', pt: '1rem' }}>
            <Divider />
          </Box>
          <Box>
            <Typography>To</Typography>
            <TextField
              value={tempMaxAmount}
              placeholder="0.00"
              onChange={(e) => { setTempMaxAmount(e.target.value); checkAmountError(e.target.value); }}
              type="number"
              variant="outlined"
              size="small"
              margin="normal"
              sx={{ m: 0, width: '120px' }}
            />
          </Box>
        </Box>
        {isAmountError &&
          <Typography sx={{ fontSize: '12px', color: variables.redPrimary, pt: '0.5rem' }}>
            Min amount cannot be greater than max amount.
          </Typography>
        }
        <Button
          fullWidth
          variant="contained"
          disabled={isAmountError || !(tempMinAmount !== '' && tempMaxAmount !== '')}
          onClick={handleAddAmountFilter}
          sx={{
            mt: '1.5rem',
            background: variables.bluePrimary,
            color: variables.darkGrayPrimary,
            boxShadow: 'none',
          }}
        >
          Add filter
        </Button>
      </GenericPopover>

      <GenericPopover open={Boolean(calendarPopover)} anchorEl={calendarPopover} style={{ width: '700px', p: '0', m: '0.9rem', mt: '-0.5rem', }} onClose={() => setCalendarPopover(null)}>
        <PeriodRangePicker onAddCalendar={handleAddCalendarFilter}></PeriodRangePicker>
      </GenericPopover>

      <GenericPopover open={Boolean(accountPopover)} anchorEl={accountPopover} style={{ width: '230px', p: '1.5rem 1rem', m: '0.9rem', mt: '-3rem', }} onClose={() => setAccountPopover(null)}>
        <CategorySelector categories={accounts} onAddCategories={handleAddAccount} />
      </GenericPopover>
    </>
  )
}

const GenericPopover = ({ open, anchorEl, style, onClose, children }) => (
  <Popover
    open={Boolean(open)}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    sx={{
      left: '322px',
      '.MuiModal-backdrop': {
        left: '322px',
      },
      '.MuiPaper-root': {
        left: '-10px !important',
      }
    }}
    PaperProps={{
      sx: {
        ...style,
        overflow: 'visible',
        borderRadius: '4px',
        background: variables.darkGrayPopover,
        '& .MuiMenuItem-root': {
          typography: 'body2',
          borderRadius: 0.75,
        },
      },
    }}
  >
    {children}
  </Popover>
);

const FilterItem = ({ label, value, width, onRemove }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', background: variables.midGrayPrimary, borderRadius: '4px', padding: '8px', height: '32px', mr: '0.5rem' }}>
    <Typography sx={{ fontSize: '12px', marginRight: '8px', color: variables.midGraySecondary }}>{label}</Typography>
    <Typography
      sx={{
        fontSize: '12px',
        marginRight: '8px',
        fontWeight: 600,
        maxWidth: width,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      {value}
    </Typography>
    <Tooltip title="Remove filter">
      <CloseIcon sx={{ fontSize: '16px', '&:hover': { color: variables.bluePrimary, cursor: 'pointer' } }} onClick={onRemove} />
    </Tooltip>
  </Box>
);

const FilterButton = ({ onClick }) => (
  <Button
    sx={{
      color: variables.lightGrayPrimary,
      fontWeight: 400,
      '&:hover': {
        background: 'transparent',
        color: variables.bluePrimary,
      }
    }}
    onClick={onClick}
  >
    <AddIcon
      sx={{
        marginRight: '4px',
        width: '1rem',
        height: '1rem',
        '&:hover': {
          color: variables.bluePrimary,
        }
      }}
    />
    Add filter
  </Button>
);

const RemoveAllFilterButton = ({ onClick }) => (
  <Button
    sx={{
      color: variables.midGraySecondary,
      fontWeight: 400,
      '&:hover': {
        background: 'transparent',
        color: variables.bluePrimary,
      }
    }}
    onClick={onClick}
  >
    <CloseIcon
      sx={{
        marginRight: '4px',
        width: '1rem',
        height: '1rem',
        '&:hover': {
          color: variables.bluePrimary,
        }
      }}
    />
    Reset all filters
  </Button>
);

const CategorySelector = ({ categories, onAddCategories }) => {
  const [filteredCategories, setFilteredCategories] = useState(categories);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const filtered = categories.filter(category =>
      category.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCategories(filtered);
  }, [searchTerm, categories]);

  const handleToggleCategory = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category) ? prev.filter((c) => c !== category) : [...prev, category]
    );
  };

  const handleAddCategories = () => {
    onAddCategories(selectedCategories);
    // Reset selections
    // setSelectedCategories([]);
  };

  const CustomFormGroup = styled(FormGroup)({
    height: '355px',
    overflow: 'auto',
    flexWrap: 'nowrap',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  });

  const StyledSearchbar = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    paddingLeft: '1rem',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    borderRadius: '4px',
    margin: '0.5rem 0 1rem',
    background: variables.darkGraySecondary,
  }));

  return (
    <Box>
      <StyledSearchbar>
        <Input
          value={searchTerm}
          autoFocus
          fullWidth
          disableUnderline
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{
                  color: variables.lightGraySecondary,
                  width: 20,
                  height: 20,
                }}
              />
            </InputAdornment>
          }
          sx={{
            mr: 1,
            fontWeight: '400',
            fontSize: '14px',
            color: variables.lightGraySecondary,
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </StyledSearchbar>
      <CustomFormGroup
        sx={{
          height: '355px',
          overflow: 'auto',
          flexWrap: 'nowrap',
          '&::webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {filteredCategories.map((category) => (
          <FormControlLabel
            sx={{
              '.MuiTypography-root': {
                fontSize: '14px',
              }
            }}
            key={category}
            control={
              <Checkbox
                sx={{
                  color: '#3C4251',
                  padding: 0,
                  margin: '0.5rem',
                  '&.MuiCheckbox-root.Mui-checked': {
                    color: variables.bluePrimary,
                  },
                }}
                checked={selectedCategories.includes(category)}
                onChange={() => handleToggleCategory(category)}
              />
            }
            label={category.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
          />
        ))}
      </CustomFormGroup>
      <Button
        fullWidth
        variant="contained"
        sx={{
          mt: '1.5rem',
          background: variables.bluePrimary,
          color: variables.darkGrayPrimary,
          boxShadow: 'none',
        }}
        onClick={handleAddCategories}
      >
        Add filter
      </Button>
    </Box>
  );
};

function PeriodRangePicker({ onAddCalendar }) {
  const [calendarPeriod, setCalendarPeriod] = useState({ start: moment().subtract(1, 'months'), end: moment() });
  const [isDirty, setIsDirty] = useState(true);

  const formatDate = (date) => moment(date).format('DD MMM YYYY');

  const minDate = moment().subtract(2, 'year').startOf('year');
  const maxDate = moment();

  const handleDateChange = (newValue, key) => {
    const newDate = moment(newValue);
    setCalendarPeriod(prevCalendarPeriod => ({ ...prevCalendarPeriod, [key]: newDate }));
    setIsDirty(true);
  };

  const handleApply = () => {
    onAddCalendar(calendarPeriod.start, calendarPeriod.end)
    setIsDirty(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
      <Box sx={{ p: 4, pb: 0, display: 'flex', flexFlow: 'column', alignItems: 'center', background: variables.lightGrayBackground }}>
        <Box className='calendar-wrapper'>
          <Box sx={{ margin: '0 30px' }}>
            <Typography sx={{ fontSize: '14px', color: variables.greenPrimary }}>Start Date</Typography>
            <Typography sx={{ fontWeight: '600' }}>{formatDate(calendarPeriod.start)}</Typography>
          </Box>
          <Typography>to</Typography>
          <Box sx={{ margin: '0 30px' }}>
            <Typography sx={{ fontSize: '14px', color: variables.greenPrimary }}>End Date</Typography>
            <Typography sx={{ fontWeight: '600' }}>{formatDate(calendarPeriod.end)}</Typography>
          </Box>
        </Box>
        <Box className='calendar-wrapper'>
          <DateCalendar
            showDaysOutsideCurrentMonth
            displayWeekNumber
            value={calendarPeriod.start}
            view={'day'}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(newValue) => handleDateChange(newValue, 'start')}
            sx={{
              margin: 0,
              '.MuiPickersDay-root.Mui-disabled': {
                color: variables.midGraySecondary + ' !important',
              },
              '.Mui-disabled': {
                textDecoration: 'line-through',
              },
              '.MuiPickersCalendarHeader-switchViewButton': {
                display: 'none',
              },
              '.Mui-selected': {
                color: variables.darkGrayPrimary + ' !important',
                '&:hover, &:focus': {
                  backgroundColor: variables.greenPrimary + ' !important',
                }
              }
            }}
          />
          <DateCalendar
            showDaysOutsideCurrentMonth
            displayWeekNumber
            value={calendarPeriod.end}
            view={'day'}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(newValue) => handleDateChange(newValue, 'end')}
            sx={{
              '.MuiPickersDay-root.Mui-disabled': {
                color: variables.midGraySecondary + ' !important',
              },
              '.Mui-disabled': {
                textDecoration: 'line-through',
              },
              '.MuiPickersCalendarHeader-switchViewButton': {
                display: 'none',
              },
              margin: 0,
              '.Mui-selected': {
                color: variables.darkGrayPrimary + ' !important',
                '&:hover, &:focus': {
                  backgroundColor: variables.greenPrimary + ' !important',
                }
              }
            }}
          />
        </Box>
      </Box>
      <Box sx={{ p: 2, pt: 0, display: 'flex', justifyContent: 'end', background: variables.lightGrayBackground }}>
        <Button
          variant="contained"
          onClick={handleApply}
          disabled={!(isDirty && calendarPeriod.end.isAfter(calendarPeriod.start))}
          sx={{ background: variables.bluePrimary, color: variables.darkGrayPrimary, boxShadow: 'none' }}
        >
          Apply
        </Button>
      </Box>
    </LocalizationProvider>
  )
}