import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clientApi from "src/client/client";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RestoreIcon from '@mui/icons-material/Restore';
import variables from 'src/styles/variables.scss';
import CustomSearchBar from "src/components/CustomSearchBar";
import 'src/styles/styles.scss';
import { useAuth } from "src/hooks/useAuth";
import { arrayToObject } from "src/shared/utils";
import { useSelector } from "react-redux";

export default function ImportHistoryPage() {
    const { user } = useAuth();
    const impersonatedUser = arrayToObject(useSelector(state => state.impersonated_settings));
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const rowsOptions = {
        "10": 10,
        "20": 20,
        "50": 50,
        "All": 'all',
    }

    const reqUser = user.isAdmin ? impersonatedUser.currentUser?.id ?? user.username : user.username;

    const fetchData = async () => {
        setLoading(true);
        const response = await clientApi().app.files.list({ user: reqUser }).catch((err) => { }).finally(() => { setLoading(false) });
        setFiles(response ?? []);

        return response
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleChangeRowsPerPage = (event, newRowsPerPage) => {
        if (newRowsPerPage !== null) {
            if (newRowsPerPage === "all") {
                setRowsPerPage(files.length); // Set rowsPerPage to the total number of files
            } else {
                setRowsPerPage(parseInt(newRowsPerPage, 10));
            }
            setPage(0); // Reset to the first page whenever the rows per page changes
        }
    };

    // useEffect(() => {
    //     if (rowsPerPage === files?.length || rowsPerPage.toString() === 'all') {
    //         setRowsPerPage(files.length); // Update rowsPerPage if files change
    //     }
    // }, [files]);

    return (
        <Box>
            <Button onClick={handleBack} sx={{ mb: '1rem', px: '1rem', color: variables.lightGrayPrimary }}>
                <ArrowBackIcon sx={{ mr: '5px', width: '30%' }} />
                Back
            </Button>
            <Typography sx={{ fontSize: '32px', mb: '1rem', px: '0.5rem', fontWeight: 500 }}>Import history</Typography>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                    <Box sx={{ width: '50%' }}>
                        <CustomSearchBar searchQuery={searchQuery} onSearchChange={handleSearchChange} placeholder={'Search by file name'}></CustomSearchBar>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <CircularProgress size={20} sx={{ display: loading ? "block" : "none", mr: '0.5rem' }}></CircularProgress>
                        <ToggleButtonGroup
                            color="primary"
                            value={rowsPerPage}
                            exclusive
                            onChange={handleChangeRowsPerPage}
                            aria-label="text alignment"
                            size='small'
                            sx={{
                                height: '40px',
                                '.MuiToggleButton-root': {
                                    px: '1rem',
                                    border: '1px solid rgba(145, 158, 171, 0.24) !important',
                                    '&.Mui-selected': {
                                        backgroundColor: variables.darkGrayPrimary,
                                        ':hover': {
                                            backgroundColor: variables.greenBackground,
                                        },
                                    },
                                },
                            }}
                        >
                            {
                                Object.keys(rowsOptions).map((type) => {
                                    return (
                                        <ToggleButton
                                            value={rowsOptions[type]}
                                            key={type}
                                            className="filter-btn"
                                            sx={{ px: '1.5rem !important' }}
                                        >
                                            {type}
                                        </ToggleButton>);
                                })
                            }
                        </ToggleButtonGroup>
                    </Box>
                </Box>
                <ImportHistoryTable
                    files={files}
                    loading={loading}
                    searchQuery={searchQuery}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    fetchData={fetchData}
                    reqUser={reqUser}
                >
                </ImportHistoryTable>
            </Box>
        </Box>
    )
}

const ObjectTableCellStyle = (theme, header = false) => {
    return {
        border: 1,
        borderColor: theme.palette.background.paper,
        background: theme.palette.background[header ? "dark" : "paper"],
        color: "white",
        padding: 1
    }
}

const ImportHistoryTable = ({ files, loading, searchQuery, page, setPage, rowsPerPage, fetchData, reqUser }) => {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const filteredFiles = files ? files?.filter(file =>
        file.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        file.originalName.toLowerCase().includes(searchQuery.toLowerCase())
    ) : [];

    const paginatedFiles = filteredFiles ? filteredFiles?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : [];

    const handleConfirmDeletion = async () => {
        if (selectedFile) {
            // setLoading(true);
            try {
                await clientApi().app.files.delete({ file: selectedFile, user: reqUser }); // Make sure to send the correct identifier
                setOpenDialog(false);
                fetchData(); // Reload data to reflect the deletion
            } catch (error) {
                console.error("Failed to delete file:", error);
                // Optionally handle errors, e.g., show an error message
            } finally {
                // setLoading(false);
                setSelectedFile(null); // Reset the delete candidate
            }
        }
    };

    const handleCancelDeletion = () => {
        setOpenDialog(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={ObjectTableCellStyle(theme, true)}>Upload Date</TableCell>
                        <TableCell sx={ObjectTableCellStyle(theme, true)}>File Name</TableCell>
                        <TableCell sx={ObjectTableCellStyle(theme, true)}>Original File Name</TableCell>
                        <TableCell sx={{ ...ObjectTableCellStyle(theme, true), textAlign: 'right', pr: '1rem' }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paginatedFiles && paginatedFiles.length > 0 &&
                        paginatedFiles.map((file, index) => (
                            <TableRow key={index}>
                                <TableCell sx={ObjectTableCellStyle(theme)}>{new Date(file.uploadDate).toLocaleDateString()}</TableCell>
                                <TableCell sx={ObjectTableCellStyle(theme)}>{file.name + file.extension}</TableCell>
                                <TableCell sx={ObjectTableCellStyle(theme)}>{file.originalName}</TableCell>
                                <TableCell sx={{ ...ObjectTableCellStyle(theme), display: 'flex', justifyContent: 'end' }}>
                                    <Tooltip title={"Roll back import"} sx={{ cursor: 'pointer' }} onClick={() => { setOpenDialog(true); setSelectedFile(file.name); }}>
                                        <RestoreIcon
                                            sx={{
                                                marginRight: '0.5rem',
                                                cursor: 'pointer',
                                                color: variables.lightGraySecondary,
                                                '&:hover': {
                                                    color: variables.orangePrimary,
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                </TableCell>

                            </TableRow>
                        ))
                    }
                    {paginatedFiles.length == 0 && !loading &&
                        <TableRow>
                            <TableCell colSpan={4} sx={ObjectTableCellStyle(theme)}>No files found</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            {rowsPerPage < files?.length && (
                <TablePagination
                    component="div"
                    count={files?.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    page={page}
                    onPageChange={handleChangePage}
                />
            )}
            <Dialog
                open={openDialog}
                onClose={handleCancelDeletion}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    '.MuiDialog-paper': {
                        height: '525px',
                        p: '5rem'
                    },
                }}
            >
                <Typography sx={{ display: 'flex', justifyContent: 'center', flexFlow: 'column', mb: '2.5rem', alignItems: 'center', fontSize: '20px', fontWeight: '500', }}>
                    <img src="/assets/icons/ic_alert_rollback.svg" alt="alert deletion icon" style={{ width: '40px', height: '40px', marginBottom: '1.5rem' }} />
                    {`Roll back import?`}
                </Typography>
                <DialogContent>
                    <Typography id="alert-dialog-description" sx={{ fontSize: '14px', mb: '2rem', textAlign: 'center' }}>
                        You are about to roll back a file import. Records imported from this file will be removed from the database.
                        <b>This action cannot be undone.</b>
                    </Typography>
                    <Typography id="alert-dialog-description" sx={{ fontSize: '14px', textAlign: 'center' }}>
                        Are you sure you want to proceed?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        onClick={handleCancelDeletion}
                        sx={{
                            color: variables.lightGrayPrimary,
                            fontWeight: 500,
                            '&:hover': {
                                background: variables.darkGraySecondary,
                            }
                        }}
                    >
                        Cancel rollback
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleConfirmDeletion}
                        autoFocus
                        sx={{
                            boxShadow: 'none',
                            fontWeight: 500,
                            background: variables.orangePrimary,
                            color: 'black',
                            '&:hover': {
                                background: variables.orangePrimary,
                                color: 'black',
                            }
                        }}
                    >
                        Confirm & roll back
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
