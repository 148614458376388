import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogActions, DialogContent, FormControl, IconButton, InputLabel, Link, MenuItem, Radio, Select, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import clientApi from "src/client/client";
import { useSnackbar } from "src/hooks/useSnackbar";
import { OrdersMappingFields, OrdersRequiredFields, TransactionsMappingFields, TransactionsRequiredFields } from "src/shared/enums";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { removeUnderscore, ucFirst } from "src/shared/utils";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useNavigate } from "react-router-dom";
import variables from 'src/styles/variables.scss';
import CloseIcon from '@mui/icons-material/Close';

export default function ImportDrawer({ user }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <LoadingButton
                variant="outlined"
                onClick={() => setOpen(true)}
                sx={{
                    height: '42px',
                    color: variables.lightGrayPrimary,
                    fontSize: '12px',
                    border: '1px solid ' + variables.borderGray,
                    borderRadius: '4px',
                    fontWeight: 400,
                    '&:hover': {
                        borderColor: variables.borderGray,
                    }
                }}
            >
                <img src='/assets/icons/ic_upload.svg' alt="upload icon" style={{ paddingRight: '0.5rem', }} />
                Import Data
            </LoadingButton>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
                fullWidth
                sx={{
                    '.MuiPaper-root': {
                        minHeight: '560px',
                        backgroundColor: variables.darkGraySecondary,
                    }
                }}
            >
                <DialogActions>
                    <IconButton onClick={() => setOpen(false)}><CloseIcon /></IconButton>
                </DialogActions>
                <DialogContent>
                    <ImportObjects user={user} />
                </DialogContent>
            </Dialog>
        </>
    )
}

function ImportObjects({ user }) {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);

    const [state, setState] = useState({
        step: 0,
        type: "",
        fileName: null,
        originalFileName: null,
        mapping: null,
        success: false
    });

    console.log(state)

    const handleObjectSelect = (type) => {
        setState({ ...state, type });
    }

    const isNextAllowed = () => {
        if (state.step == 0 && state.type != "") {
            return true
        }

        if (state.step == 1 && state.fileName != null) {
            return true
        }

        if (state.step == 2 && state.mapping != null) {
            return true
        }

        return false;
    }

    function getFileType(filename) {
        const extension = filename.slice(filename.lastIndexOf('.') + 1).toLowerCase();
        return extension === 'csv' ? 'csv' : 'excel'; // Simplistic approach: assume non-csv as excel
    }

    const handleImport = async () => {
        setLoading(true);

        const result = await clientApi().app.files.import({ user: user }, {
            type: "s3",
            file_type: getFileType(state.originalFileName),
            original_file_name: state.originalFileName,
            file_name: state.fileName,
            import_type: state.type,
            mapping: state.mapping
        }).catch(err => { }).finally(() => {
            setLoading(false);
        });

        if (result != null) {
            setState({ ...state, success: true, step: state.step + 1 });
        }
    }

    const handleNext = () => {
        setState({ ...state, step: state.step + 1 });
    }

    return (
        <Stack sx={{ height: "90%" }} justifyContent={"center"} alignItems={"center"}>
            {
                state.step == 0 &&
                <>
                    <Typography
                        mt={5}
                        sx={{
                            fontSize: '20px',
                            fontWeight: 400,
                        }}
                    >
                        Select the type of data you want to import
                    </Typography>
                    <Stack direction={"row"} mt={5} mb={5} spacing={4}>
                        <ImportObjectSelector
                            type={"transactions"}
                            text={"Bank statements"}
                            onSelect={handleObjectSelect}
                            checked={state.type == "transactions"}
                        ></ImportObjectSelector>

                        <ImportObjectSelector
                            type={"orders"}
                            text={"Orders"}
                            onSelect={handleObjectSelect}
                            checked={state.type == "orders"}
                        >
                        </ImportObjectSelector>
                    </Stack>
                </>
            }

            {
                state.step == 1 &&
                <UploadFile user={user} type={state.type} onSuccess={(fileName, originalFileName) => setState({ ...state, fileName, originalFileName })}></UploadFile>
            }
            {
                state.step == 2 &&
                <DataMapper user={user} type={state.type} fileName={state.fileName}
                    onSuccess={(values) => setState({ ...state, mapping: values })}
                ></DataMapper>
            }
            {
                state.step == 3 &&
                <ImportSuccess type={state.type} />
            }

            <Stack sx={{ marginTop: "auto", width: '100%' }} direction={"row"} justifyContent={"center"} spacing={3}>
                {
                    state.step != 3 &&
                    (
                        <>
                            {state.step != 0 &&
                                <LoadingButton variant="outlined" loading={false} disabled={true}>Prev</LoadingButton>
                            }
                            <LoadingButton variant="outlined" loading={loading} disabled={!isNextAllowed()} onClick={() => {
                                state.step == 2 ? handleImport() : handleNext();
                            }}>{
                                    state.step == 2 ? "Submit import request" : "Continue"
                                }</LoadingButton>
                        </>
                    )
                }
            </Stack>
        </Stack>
    )
}

const ImportSuccess = ({ type }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const handleButtonClick = () => {
        navigate(type === 'transactions' ? '/overview' : '/performance-kpis');
    };

    return (
        <Stack alignItems={"center"} mt={5} spacing={3}>
            <DoneAllIcon style={{ fontSize: 100, color: theme.palette.primary.main }} />
            <Typography variant="h5" gutterBottom>
                Import started
            </Typography>
            <Typography variant="body1" gutterBottom>
                The import was queued to be processed. We will notify you when the data is ready for usage.
            </Typography>
            <Typography variant="body1" gutterBottom>
                You can go to the dashboard to check changes.
            </Typography>
            <LoadingButton variant="outlined" color="primary" onClick={handleButtonClick}>
                Go to {type === 'transactions' ? 'Cashflow' : 'Orders'}
            </LoadingButton>
        </Stack>
    );
}

const DataMapperCellStyle = (theme, header = false) => {
    return {
        border: 3,
        borderColor: theme.palette.background.paper,
        background: theme.palette.background[header ? "dark" : "paper"],
        color: "white",
        padding: 1,
        maxWidth: '300px',
    }
}

function DataMapper({ type, fileName, user, onSuccess }) {
    const [loading, setLoading] = useState(null);
    const [sheets, setSheets] = useState([]);


    const theme = useTheme();
    const mappingFields = type == "transactions" ? TransactionsMappingFields : OrdersMappingFields;
    const requiredFields = type == "transactions" ? TransactionsRequiredFields : OrdersRequiredFields;

    const fetchData = async () => {
        setLoading(true);
        onSuccess && onSuccess(null);

        const result = await clientApi().app.files.schema({ file: fileName, user: user }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });

        const _sheets = Object.values(result?.sheets ?? []);

        _sheets.forEach((sheet, index) => {
            sheet.values = {};
            sheet.isActive = (index == 0) ? true : false
        });

        console.log(_sheets);
        setSheets(_sheets);
    }

    const handleSheetChange = (event) => {
        const activeSheetName = event.target.value;
        setSheets(sheets.map(sheet => ({
            ...sheet,
            isActive: sheet.name === activeSheetName
        })));
    };

    const handleImportTypeChange = (event, field) => {
        const activeSheet = sheets.find(s => s.isActive == true);
        const value = event.target.value;

        activeSheet.values[field] = { action: value };
        setSheets([...sheets]);
    }

    const handleFieldMappingChange = (event, field) => {
        const activeSheet = sheets.find(s => s.isActive == true);
        const value = event.target.value;

        activeSheet.values[field] = { ...activeSheet.values[field], field: value };

        setSheets([...sheets]);

        const activatedFields = Object.values(activeSheet.values).filter(v => v.action == "internal").map(v => v.field);
        const missingFields = requiredFields.filter(f => !activatedFields.includes(f));

        if (missingFields.length == 0) {
            onSuccess && onSuccess({
                sheet: activeSheet.name,
                values: activeSheet.values
            });
        } else {
            onSuccess && onSuccess(null);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const activeSheet = sheets.find(s => s.isActive == true) ?? null;
    const excludedFields = Object.values(activeSheet?.values ?? {}).map(v => v.field);

    return (
        <>
            {
                loading != false && (
                    <Stack alignItems={"center"} spacing={0.5} mt={5}>
                        <Skeleton width={"100%"} />
                        <Skeleton width={"100%"} />
                        <Skeleton width={"100%"} />
                    </Stack>
                )
            }
            {
                loading == false && (
                    <Box sx={{ marginTop: 5, height: "100%" }}>
                        <Stack direction="row" spacing={1}>
                            <FormControl>
                                <InputLabel id="sheet-select-label">Sheet selected for mapping</InputLabel>
                                <Select
                                    size="small"
                                    value={sheets.find(el => el.isActive == true)?.name ?? ""}
                                    onChange={handleSheetChange}
                                    sx={{ width: "200px" }}
                                    id="sheet-select"
                                    label="Sheet selected for mapping"
                                    labelId="sheet-select-label"
                                >
                                    {sheets.map((sheet) => (<MenuItem key={"sheet" + sheet.name} value={sheet.name}>{sheet.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                            <Box>
                                <LoadingButton variant="outlined" loading={loading} onClick={fetchData}>Refresh</LoadingButton>
                            </Box>
                        </Stack>
                        <TableContainer component={Box} sx={{ marginTop: 3, maxHeight: '550px', overflow: 'auto' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={DataMapperCellStyle(theme, true)}>Column name from file</TableCell>
                                        <TableCell sx={DataMapperCellStyle(theme, true)}>Preview values for column</TableCell>
                                        <TableCell sx={DataMapperCellStyle(theme, true)}>Mapping action</TableCell>
                                        <TableCell sx={DataMapperCellStyle(theme, true)}>Mapped property</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        activeSheet?.header.map((header) => {
                                            return (
                                                <TableRow key={"field_" + header}>
                                                    <TableCell sx={DataMapperCellStyle(theme)}>{ucFirst(removeUnderscore(header))}</TableCell>
                                                    <TableCell sx={DataMapperCellStyle(theme)}>
                                                        {
                                                            activeSheet?.preview[header].map((value, index) => {
                                                                return (
                                                                    <Typography
                                                                        variant="body2"
                                                                        key={"value_" + index}
                                                                        sx={{ maxWidth: '270px', overflow: 'auto' }}
                                                                    >
                                                                        {value}
                                                                    </Typography>
                                                                )
                                                            })
                                                        }
                                                    </TableCell>
                                                    <TableCell sx={DataMapperCellStyle(theme)}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                sx={{ width: "250px" }}
                                                                size="small"
                                                                id="mapping-action-select"
                                                                labelId="mapping-action-select-label"
                                                                value={activeSheet.values[header]?.action ?? "ignore"}
                                                                onChange={(event) => {
                                                                    handleImportTypeChange(event, header)
                                                                }}
                                                            >
                                                                <MenuItem value="ignore">Don't import this field</MenuItem>
                                                                <MenuItem value="internal">Map internal value</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell sx={DataMapperCellStyle(theme)}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                sx={{ width: "250px" }}
                                                                size="small"
                                                                id="mapping-field-select"
                                                                labelId="mapping-field-select-label"
                                                                value={activeSheet.values[header]?.field ?? ""}
                                                                disabled={activeSheet.values[header]?.action != "internal"}
                                                                onChange={(event) => {
                                                                    handleFieldMappingChange(event, header)
                                                                }}
                                                            >
                                                                {
                                                                    mappingFields.map((field) => {
                                                                        return (<MenuItem disabled={excludedFields.includes(field)} value={field} key={field}>{ucFirst(removeUnderscore(field))}</MenuItem>)
                                                                    })
                                                                }

                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )
            }
        </>
    )
}

function UploadFile({ type, user, onSuccess }) {
    const theme = useTheme();

    const { openError } = useSnackbar();

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);

    const [loading, setLoading] = useState(false);

    const handleChange = async (file) => {
        setFile(file);
    }

    const handleSubmit = async () => {
        setLoading(true);

        const formData = new FormData();
        formData.append("file", file);

        const result = await clientApi().app.files.upload(formData, { name: file?.name, user_key: user }).catch(err => {
        }).finally(() => {
            setLoading(false)
        });

        if (result?.fileName && result?.originalFileName) {
            setFileName(result.fileName);
            onSuccess && onSuccess(result.fileName, result.originalFileName)
        }
    };


    return (
        <>
            <Typography variant="h4" mt={5}>Upload your '{type}' file</Typography>
            <Typography variant="body1" mt={5}>Before you upload your files below, make sure your file is ready to be imported <Link href="#">Download the sample template</Link></Typography>
            <Typography variant="h6" mt={5}>1. Choose a file by selecting or drag and drop</Typography>


            <Box sx={{
                marginTop: 3,
                height: "100px",
                border: "1px dashed #f1f1f1",
                "& label": {
                    border: 0,
                    maxWidth: "inherit",
                    height: "100px",
                },
                "& path": {
                    fill: "#cccccc"
                }

            }}>
                {
                    file == null &&
                    <FileUploader
                        handleChange={(file) => handleChange(file)}
                        name="file"
                        multiple={false}
                        required
                        types={["xls", "xlsx", "csv"]}
                    />
                }
                {
                    file != null && (
                        <Stack spacing={3} direction={"row"} alignItems={"center"} justifyContent={"center"} sx={{ height: "100%" }} >
                            <Typography>{file.name}</Typography>
                            <IconButton onClick={() => {
                                setFile(null);
                                setFileName(null);
                                onSuccess && onSuccess(null);
                            }}><DeleteForeverIcon /></IconButton>
                        </Stack>
                    )
                }
            </Box>

            <Typography variant="h6" mt={5}>2. Upload the file to the storage</Typography>
            <Box sx={{ marginTop: 3 }}>
                {fileName != null && <Typography variant="body1" color={theme.palette.primary.main}>File uploaded successfully</Typography>}
                {fileName == null && <LoadingButton variant={"outlined"} disabled={file == null} loading={loading} onClick={handleSubmit}>Upload</LoadingButton>}
            </Box>

        </>
    )
}

function ImportObjectSelector({ type, text, onSelect, checked }) {
    const theme = useTheme();

    const selected = {
        transform: 'scale(1.01)', // Scale the box up to 110% when hovered
        background: theme.palette.grey[900],
        cursor: 'pointer',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)'
    }
    const box_style = {
        padding: 1,
        border: 1,
        borderColor: theme.palette.grey[700],
        borderRadius: 1,
        width: 160,
        height: 240,
        transition: 'transform 0.3s', // Add transition for smooth scaling
        ':hover': selected,
    }

    return (
        <Box onClick={() => {
            onSelect(type);
        }}>
            <Stack sx={box_style} alignItems={"center"} spacing={3} justifyContent={"space-between"}>
                <Box sx={{ mt: '1rem', display: 'flex', alignItems: 'center', flexFlow: 'column' }}>
                    <img src={`/assets/illustrations/${type}.png`} alt="statements" width={100} />
                    <Typography textAlign={"center"} mt={"1rem"}>{text}</Typography>
                </Box>
                <Radio checked={checked} sx={{ mx: "auto", my: 0 }} />
            </Stack>
        </Box>
    )
}