import { Input, InputAdornment, styled } from "@mui/material";
import Iconify from "./iconify";
import variables from 'src/styles/variables.scss';

export default function CustomSearchBar({ searchQuery, onSearchChange, placeholder }) {
    const StyledSearchbar = styled('div')(({ theme }) => ({
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        paddingLeft: '1rem',
        margin: '0 0.5rem 1rem',
        border: '1px solid rgba(145, 158, 171, 0.32)',
        borderRadius: '4px',
        background: variables.darkGraySecondary,
    }));

    return (
        <StyledSearchbar>
            <Input
                value={searchQuery}
                autoFocus
                fullWidth
                disableUnderline
                placeholder={placeholder}
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify
                            icon="eva:search-fill"
                            sx={{
                                color: variables.lightGraySecondary,
                                width: 20,
                                height: 20
                            }}
                        />
                    </InputAdornment>
                }
                sx={{
                    mr: 1,
                    fontWeight: '400',
                    color: variables.lightGraySecondary,
                }}
                onChange={onSearchChange}
            />
        </StyledSearchbar>
    );
}