import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, CircularProgress } from '@mui/material';

const CenteredContent = styled('div')(() => ({
    position:'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    top:'50%',
    left:"50%"
}));

export default function LoadingPage() {
  return (
    <>
      <Helmet>
        <title>Eleni Finance</title>
      </Helmet>

      <Container>
            <CenteredContent>
                <CircularProgress />
            </CenteredContent>
      </Container>
    </>
  );
}