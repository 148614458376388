import 'moment/locale/en-gb';
import { useDataProvider } from "src/hooks/useDataProvider";
import { DataFilterGranularities } from "src/shared/enums";
import CashflowCard from "../CashflowCard";
import { useEffect } from "react";
import { Box } from "@mui/material";

export default function CashInCard({ fullscreen = false }) {
    const dataProvider = useDataProvider();

    useEffect(() => {
        const newFilter = {
            ...dataProvider.filter, path: `sets/cashflow`,
            fullscreen: fullscreen,
        };
        dataProvider.setFilter(newFilter);
    }, []);

    const currency = dataProvider.filter.currency.toUpperCase();

    const { cashflowSet: widgetData } = dataProvider?.data ?? [];

    const actualsSeries = widgetData?.actualsIncome ?? [];
    const forecastSeries = widgetData?.forecastIncome ?? [];

    const lastWeekValue = actualsSeries[actualsSeries.length - 2]?.value ?? 0;
    const thisWeekValue = actualsSeries[actualsSeries.length - 1]?.value ?? 0;
    const nextWeekValue = forecastSeries[dataProvider.filter.granularity === DataFilterGranularities.weekly ? 0 : 1]?.value?.average ?? 0;
    const diffNow = thisWeekValue - lastWeekValue;
    const diffForecast = nextWeekValue - thisWeekValue;
    const percentageDiffNow = lastWeekValue !== 0 ? ((diffNow / lastWeekValue) * 100).toFixed(2) : "N/A";
    const percentageDiffForecast = thisWeekValue !== 0 ? ((diffForecast / thisWeekValue) * 100).toFixed(2) : "N/A";

    return (
        <>
            <Box sx={{ display: 'flex', }}>
                <CashflowCard
                    id={'card-in'}
                    titleNow={'Cash In '}
                    valueNow={thisWeekValue}
                    valueForecast={nextWeekValue}
                    diffNow={diffNow}
                    diffForecast={diffForecast}
                    percentageDiffNow={percentageDiffNow}
                    percentageDiffForecast={percentageDiffForecast}
                    currency={currency}
                    fullscreen={fullscreen}
                    granularity={dataProvider.filter.granularity}
                />
            </Box>
        </>
    );
}