import ShowChartIcon from '@mui/icons-material/ShowChart';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import variables from 'src/styles/variables.scss';

export const iconComponents = {
  "BalanceIcon": <img src="/assets/icons/ic_balance.svg" alt="balance icon" style={{ paddingRight: '4px' }}/>,
  "CashInIcon": <img src="/assets/icons/ic_cash_inflow.svg" alt="cash inflow icon" style={{ paddingRight: '4px' }}/>,
  "CashOutIcon": <img src="/assets/icons/ic_cash_outflow.svg" alt="cash outflow icon" style={{ paddingRight: '4px' }}/>,
  "NetCashIcon": <img src="/assets/icons/ic_net_cashflow.svg" alt="net cashflow icon" style={{ paddingRight: '4px' }}/>,
};