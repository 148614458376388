import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import variables from 'src/styles/variables.scss';
import { SnackbarProvider } from 'src/hooks/useSnackbar';
import { FadeTransitions } from 'src/components/Transitions';
import PushNotificationsProvider from 'src/hooks/useNotifications';
import Joyride from 'react-joyride';
import { SettingTypes } from 'src/shared/enums';
import clientApi from 'src/client/client';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalActionTypes } from 'src/redux/globals';
import { UserSettingsProvider, useUserSettings } from 'src/hooks/useUserSettings';
import AdminHeader from './adminHeader';
import { useAuth } from 'src/hooks/useAuth';
import { Box, CircularProgress, Container } from '@mui/material';
import { arrayToObject } from 'src/shared/utils';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

export default function DashboardLayout() {
  const { user } = useAuth();
  const { set, isReady } = useUserSettings();
  const dispatch = useDispatch();
  const globalSettings = arrayToObject(useSelector(state => state.settings));
  const [open, setOpen] = useState(false);
  const [runTour, setRunTour] = useState(false);

  const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
  });

  const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: user.isAdmin ? APP_BAR_MOBILE + 78 : APP_BAR_MOBILE + 28,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  useEffect(() => {
    const fetchData = () => {
      if (globalSettings?.first_auth === 'true') {
        setRunTour(true);
      }
    };

    fetchData();
  }, []);

  const handleJoyrideCallback = async (data) => {
    const { status, action } = data;
    if ([status === 'finished', status === 'skipped'].includes(true) || action === 'close') {
      setRunTour(false);
      await set(SettingTypes.first_auth, 'false');
      dispatch({ type: GlobalActionTypes.USER_SETTINGS, payload: [{ "name": SettingTypes.first_auth, "value": 'false' }] });
    }
  };

  return (
    <FadeTransitions>
      <PushNotificationsProvider>
        <SnackbarProvider>
          {user.isAdmin &&
            <Box
              sx={{
                height: '4px',
                background: variables.orangePrimary,
                position: 'fixed',
                top: 0,
                zIndex: '2000',
                width: '100%',
              }}></Box>
          }
          <StyledRoot>
            {runTour &&
              <Joyride
                continuous={false}
                run={runTour}
                steps={[
                  {
                    target: '.connectors-btn',
                    content: 'Get started here by connecting your bank account(s).',
                    placement: 'right',
                    disableBeacon: true,
                  },
                ]}
                callback={handleJoyrideCallback}
                styles={{
                  options: {
                    arrowColor: variables.darkGraySecondary,
                    backgroundColor: variables.darkGraySecondary,
                    overlayColor: 'rgba(79, 26, 0, 0.4)',
                    primaryColor: variables.darkGrayPrimary,
                    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
                    textColor: variables.lightGrayPrimary,
                    zIndex: 10000,
                  },
                }}
              />
            }
            {user.isAdmin &&
              <AdminHeader onOpenNav={() => setOpen(true)} />
            }
            <Header onOpenNav={() => setOpen(true)} />
            <Nav openNav={open} onCloseNav={() => setOpen(false)} />
            {isReady() &&
              <>
                <Main
                  sx={{
                    backgroundColor: variables.darkGrayPrimary,
                  }}
                >
                  <Outlet />
                </Main>
              </>
            }
            {!isReady() &&
              <Container>
                <Box sx={{
                  position: 'absolute',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  top: '50%',
                  left: "50%"
                }}>
                  <CircularProgress />
                </Box>
              </Container>
            }

          </StyledRoot>
        </SnackbarProvider>
      </PushNotificationsProvider>
    </FadeTransitions>
  );
}