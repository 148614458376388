import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DataProvider } from 'src/hooks/useDataProvider';
import { WidgetMapping } from './widgets';
import { Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import variables from 'src/styles/variables.scss';

// Import other components

function FullWidthComponent() {
    const { widgetType } = useParams(); // Retrieve the widget type from URL parameters
    const location = useLocation(); // Access location object
    const navigate = useNavigate();
    const config = location.state?.config; // Retrieve the config passed via state

    // Retrieve the specific widget component based on the widgetType
    const WidgetComponent = WidgetMapping[widgetType]?.widget;

    if (!WidgetComponent) {
        return <div>Widget not found</div>;
    }

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Box>
            <Button onClick={handleBack} sx={{ mb: '1.5rem', px: '1rem', color: variables.lightGrayPrimary }}>
                <ArrowBackIcon sx={{ mr: '5px', width: '30%' }} />
                Back
            </Button>
            <Box sx={{ position: 'relative' }}>
                <DataProvider filterConfig={config?.enabledFilters} styles={{ position: 'absolute', top: '1.5rem', right: '1.5rem', zIndex: 2, }}>
                    <WidgetComponent fullscreen={true} />
                </DataProvider>
            </Box>
        </Box>
    );
};

export default FullWidthComponent;