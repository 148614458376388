export function transformDate(dateStr) {
  if (dateStr.includes('-W') || dateStr.includes('-w')) {
    return dateStr.replace('-w', '-W');
  }

  const parts = dateStr.split('_');
  const month = parts[0];
  const year = parts[1];

  const monthNames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
  const monthNumber = monthNames.indexOf(month) + 1;
  const monthStr = monthNumber < 10 ? `0${monthNumber}` : monthNumber;

  return `${year}-${monthStr}`;
}

export function getDatesInRange(startDateStr) {
  const startDate = new Date(startDateStr);
  const currentDate = new Date();
  const datesInRange = [];

  // Check if startDate is valid
  if (isNaN(startDate.getTime())) {
    return datesInRange; // Return empty array for invalid startDate
  }

  let currentDateIter = new Date(startDate); // Start from the provided date

  // Loop until currentDate, adding dates to the array
  while (currentDateIter <= currentDate) {
    datesInRange.push(currentDateIter.toLocaleDateString('en-GB')); // Format as DD/MM/YYYY
    currentDateIter.setDate(currentDateIter.getDate() + 1); // Increment date by 1 day
  }

  return datesInRange;
}


export function getLastXDaysSeries(x, jump) {
  const today = new Date();
  const dates = [];

  for (let i = x - 1; i >= 0; i--) {
    if (i % jump !== 0) continue;

    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() - i);

    const dd = String(currentDate.getDate()).padStart(2, '0');
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const yyyy = currentDate.getFullYear();

    const formattedDate = `${dd}/${mm}/${yyyy}`;
    dates.push(formattedDate);
  }

  return dates;
}

export function insertOrUpdateArray(arr, newArray) {
  const updatedArray = arr.map((existingObj) => {
    const matchingIndex = newArray.findIndex((newObj) => newObj.name === existingObj.name);

    // If an object with the same name exists in the new array, update it
    if (matchingIndex !== -1) {
      return { ...existingObj, ...newArray[matchingIndex] }; // Merge the existing object with the new object
    }

    return existingObj;
  });

  // Insert any new objects that don't exist in the original array
  newArray.forEach((newObj) => {
    if (!updatedArray.some((obj) => obj.name === newObj.name)) {
      updatedArray.push(newObj);
    }
  });

  return updatedArray;
}

export const arrayToObject = (array) => {
  return array.reduce((acc, obj) => {
    acc[obj.name] = obj.value;
    return acc;
  }, {});
};

export function convertJsonToQueryString(jsonObject) {
  const params = new URLSearchParams();

  for (const key in jsonObject) {
    if (jsonObject.hasOwnProperty(key)) {
      params.append(key, jsonObject[key]);
    }
  }

  return params.toString();
}

export function fillArrayPosition(array, position, value) {
  array[position] = value;

  for (let i = 0; i < position; i++) {
    array[i] = array[i] ? array[i] : null;
  }

  return array;
}

export function mergeArraysByKey(array1, array2, key) {
  const _array1 = array1 ?? [];
  const _array2 = array2 ?? [];

  const mergedArray = _array1.map(item1 => {
    const matchingItem = _array2.find(item2 => item2[key] === item1[key]);

    if (matchingItem) {
      return { ...item1, ...matchingItem };
    }

    return item1;
  });

  _array2.forEach(item2 => {
    if (!_array1.some(item1 => item1[key] === item2[key])) {
      mergedArray.push(item2);
    }
  });

  return mergedArray;
}




export function objectToUrlParams(obj) {
  const params = Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
  return params != "" ? params : null;
};

export function decodeJwt(jwt) {
  const [header, payload] = jwt?.split('.').slice(0, 2);

  if (payload == null) return null;

  return JSON.parse(window.atob(payload));
}

export function removeUnderscore(str) {
  const _str = str.replace(/_/g, ' ').trim();
  return _str.charAt(0).toUpperCase() + _str.slice(1);
}

export function removeFileExtension(fileName) {
  return fileName.replace(/\.[^/.]+$/, ''); // Removes everything after the last dot (.)
}

export function ucFirst(str) {
  if (!str)
    return str;

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function extractNameFromEmail(email) {
  const parts = email.split('@');

  if (parts.length === 2) {
    const nameParts = parts[0].split('.');

    if (nameParts.length > 1) {
      const [firstName, lastName] = nameParts;

      return {
        firstName: ucFirst(firstName) || '',
        lastName: ucFirst(lastName) || '',
      };

    } else {
      return {
        firstName: ucFirst(nameParts[0]) || '',
        lastName: ucFirst(nameParts[1]) || '',
      };
    }
  }

  return {
    firstName: '',
    lastName: '',
  };
}

export function formatAmount(value) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);
}

export const formatLabel = (label) => {
  // Replace underscores with spaces and capitalize the first character
  return label.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase());
};