/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'eu-west-2',
    aws_cognito_region: 'eu-west-2',
    aws_user_pools_id: 'eu-west-2_oarAPBFu5',
    aws_user_pools_web_client_id: '3fdf5esj1o2c1o1fhh5r688lbl',
    oauth: {
      domain: 'eleniproduction.auth.eu-west-2.amazoncognito.com',
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin', 'phone'],
      redirectSignIn: 'https://app.eleni.finance/overview/',
      redirectSignOut: 'https://app.eleni.finance/',
      responseType: 'token',
    },
    federationTarget: 'COGNITO_USER_POOLS',
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: ['GOOGLE'],
    aws_cognito_signup_attributes: ['EMAIL', 'PHONE_NUMBER'],
    aws_cognito_mfa_configuration: 'ON',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_UPPERCASE', 'REQUIRES_NUMBERS', 'REQUIRES_SYMBOLS'],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
  };
  
  export default awsmobile;