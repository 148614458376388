'use client'
import {motion, AnimatePresence} from "framer-motion";

export function Transitions({children, reverse, paddingTop}) {
    const properties = reverse ? {
        initial : {opacity:0, x:100}, 
        animate: {opacity:1, x:0}, 
        exit: {opacity:0, x:100}, 
        transition: {delay:0.25 }
    } : {
        initial : {opacity:0, x:-100}, 
        animate: {opacity:1, x:0}, 
        exit: {opacity:1, x:-100}, 
        transition: {delay:0.25 }
    };

    return (
        <AnimatePresence>
            <motion.div 
                initial={properties.initial}
                animate={properties.animate}
                exit={properties.exit}
                transition={properties.transition}
                style={{
                    height:"100%", 
                    width:"100%",
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    justifyContent:"center",
                    paddingTop: paddingTop || 0
                }}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    )
}

export function FadeTransitions({children, reverse, paddingTop}) {
    const properties = reverse ? {
        initial : {opacity:0, x:0}, 
        animate: {opacity:1, x:0}, 
        exit: {opacity:0, x:0}, 
        transition: {delay:0.25 }
    } : {
        initial : {opacity:0, x:0}, 
        animate: {opacity:1, x:0}, 
        exit: {opacity:1, x:0}, 
        transition: {delay:0.25 }
    };

    return (
        <AnimatePresence>
            <motion.div 
                initial={properties.initial}
                animate={properties.animate}
                exit={properties.exit}
                transition={properties.transition}
                style={{
                    height:"100%", 
                    width:"100%",
                    paddingTop: paddingTop || 0
                }}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    )
}