import React, { createContext, useContext, useEffect, useState } from "react";
import clientApi from "src/client/client";
import { Box, Button, CircularProgress, Divider, FormControl, MenuItem, Popover, Select, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Currencies, DataFilterGranularities, DataFilterPeriodTypes, DataFilterScenarioTypes, DataFilterScenarios, DataProviderFilter, EnvironmentTypes, TransactionsTypes } from "src/shared/enums";
import { useSelector, useStore } from "react-redux";
import { arrayToObject } from "src/shared/utils";
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'src/styles/styles.scss';
import variables from 'src/styles/variables.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth } from "./useAuth";
import { useUserSettings } from "./useUserSettings";

const DataProviderContext = createContext();

export const useDataProvider = () => {
  const context = useContext(DataProviderContext);
  if (!context) {
    throw new Error('useDataProvider must be used within a DataProviderContext.Provider');
  }
  return context;
};

export const DataProvider = ({ children, filterConfig = {}, styles = {}, hideLoading }) => {
  const { user } = useAuth();
  const { isReady } = useUserSettings();
  const globalSettings = arrayToObject(useSelector(state => state.settings));
  const impersonatedSettings = arrayToObject(useSelector(state => state.impersonated_settings));

  // console.log(globalSettings)
  // console.log(impersonatedSettings)

  const dataSettings = user.isAdmin && impersonatedSettings?.currentUser ? impersonatedSettings : globalSettings;
  const [filter, setFilter] = useState({
    user: user.isAdmin ? impersonatedSettings.currentUser?.id ?? null : null,
    path: null,
    granularity: DataFilterGranularities.weekly,
    steps: 30,
    currency: dataSettings.currency ?? Currencies.gbp,
    environment: dataSettings.environment ?? EnvironmentTypes.live,
    scenario: DataFilterScenarios.average,
    forecast: false,
    country: 'all',
    fullscreen: false,
    start: moment().subtract(12, 'week').startOf('week').format('YYYY-MM-DD'),
    end: moment().add(12, 'week').endOf('week').format('YYYY-MM-DD'),
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async (_filter) => {
    setLoading(true);

    //no need to send the path to the api 
    const dataFilter = { ..._filter };
    delete dataFilter.path;

    //request data 
    const result = await clientApi().data.request(`data/${_filter.path}`, dataFilter).catch((error) => { }).finally(() => setLoading(false));
    setData(result);
  }

  const prepareData = async () => {
    setLoading(true);

    const result = await clientApi().data.prepare(`prepare`, { user: filter.user }).catch((error) => { }).finally(() => setLoading(false));
  }

  useEffect(() => {

    if (filter.path == null)
      return;

    // console.log("main use effect", filter)
    fetchData({ ...filter });
  }, [filter]);

  useEffect(() => {
    if (isReady() && (dataSettings.environment != filter.environment || dataSettings.currency != filter.currency)) {
      console.log("setting use effect", filter)
      const newFilter = { ...filter, environment: dataSettings.environment, currency: dataSettings.currency };
      setFilter(newFilter);
    }
  }, [dataSettings])

  const contextValue = {
    loading,
    data,
    dataAsArray: () => { return (data && typeof data == "object") ? Object.values(data) : (data ?? []) },
    filter,
    fetchData,
    prepareData,
    setData,
    setFilter
  };

  return (
    <DataProviderContext.Provider value={contextValue}>
      <DataFilter loading={loading} config={filterConfig} hideLoading={hideLoading} styles={styles}></DataFilter>
      {
        React.Children.map(children, child => {
          if (child != null)
            return React.cloneElement(child, { loading: loading });
          return null;
        })
      }
    </DataProviderContext.Provider>
  );
};

const DataFilter = ({ loading, config = {}, hideLoading = false, styles }) => {

  return (
    <Stack direction={"row"} justifyContent="right" alignItems={"center"} spacing={2}
      sx={styles}
    >
      {!hideLoading &&
        <CircularProgress size={20} sx={{ display: loading ? "block" : "none" }}></CircularProgress>
      }
      {
        Object.keys(config).includes(DataProviderFilter.country) &&
        <DataFilterSelector label={'country'} filter={'country'}></DataFilterSelector>
      }
      {
        Object.keys(config).includes(DataProviderFilter.scenario) &&
        <DataFilterButtonPicker
          config={config[DataProviderFilter.scenario]}
          filterField={"scenario"}
          ariaLabel={"Scenario picker"}
          options={DataFilterScenarioTypes}
        ></DataFilterButtonPicker>
      }
      {
        Object.keys(config).includes(DataProviderFilter.period) &&
        <DataFilterButtonPicker
          config={config[DataProviderFilter.period]}
          filterField={"steps"}
          ariaLabel={"Period"}
          options={DataFilterPeriodTypes}
        ></DataFilterButtonPicker>
      }
      {
        Object.keys(config).includes(DataProviderFilter.granularity) &&
        <DataFilterButtonPicker
          config={config[DataProviderFilter.granularity]}
          filterField="granularity"
          ariaLabel={"Granularity"}
          options={DataFilterGranularities}
        ></DataFilterButtonPicker>
      }
      {
        Object.keys(config).includes(DataProviderFilter.periodRange) &&
        <PeriodRangePicker></PeriodRangePicker>
      }
      {
        Object.keys(config).includes(DataProviderFilter.transaction) &&
        <DataFilterButtonPicker
          config={config[DataProviderFilter.transaction]}
          filterField="transactionType"
          ariaLabel={"Transaction Type"}
          options={TransactionsTypes}
        ></DataFilterButtonPicker>
      }
    </Stack>
  )
}

function DataFilterButtonPicker({ config, ariaLabel, filterField, options }) {
  const dataProvider = useDataProvider();

  const handleChange = (event, newPeriod) => {
    if (newPeriod == null)
      return

    const newFilter = { ...dataProvider.filter }
    newFilter[filterField] = newPeriod
    if (filterField == "granularity" && newPeriod == DataFilterGranularities.monthly) {
      newFilter.end = moment().add(2, 'month').endOf('month').format('YYYY-MM-DD')
    }
    dataProvider.setFilter(newFilter);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
      <ToggleButtonGroup
        color="primary"
        value={dataProvider.filter[filterField]}
        exclusive
        onChange={handleChange}
        aria-label={ariaLabel}
        size='small'
        sx={{
          height: '38px',
          '.MuiToggleButton-root': {
            px: '1rem',
            border: '1px solid rgba(145, 158, 171, 0.24) !important',
            '&.Mui-selected': {
              backgroundColor: variables.darkGrayPrimary,
              ':hover': {
                backgroundColor: variables.greenBackground,
              },
            },
          },
        }}
      >
        {
          Object.keys(options).map((type) => {
            const enabledButtons = config?.options ?? Object.keys(options);
            if (!enabledButtons.includes(type))
              return null;

            return (
              <ToggleButton
                value={options[type]}
                key={type}
                className="filter-btn"
              >
                {type}
              </ToggleButton>);
          })
        }
      </ToggleButtonGroup>
    </Box>
  );
}

function PeriodRangePicker({ }) {
  const dataProvider = useDataProvider();
  const isWeeklyGranularity = dataProvider.filter.granularity === DataFilterGranularities.weekly;

  const [calendarPeriod, setCalendarPeriod] = useState({ start: null, end: null });
  const [calendarView, setCalendarView] = useState(isWeeklyGranularity ? 'day' : 'month');
  const [isDirty, setIsDirty] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setCalendarView(newView);
    }
  };

  useEffect(() => {
    setCalendarPeriod({
      start: moment(dataProvider.filter.start),
      end: moment(dataProvider.filter.end)
    });
  }, [dataProvider.filter.start, dataProvider.filter.end]);

  useEffect(() => {
    setCalendarView(isWeeklyGranularity ? 'day' : 'month');
  }, [dataProvider.filter.granularity]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const formatDate = (date) => moment(date).format(isWeeklyGranularity ? 'DD MMM YYYY' : 'MMM YYYY');

  const minDate = dataProvider.filter.forecast ? moment().startOf('month') : moment().subtract(2, 'year').startOf('year');
  const maxDate = dataProvider.filter.forecast ? moment().add(3, 'months').endOf('month') : moment().add(6, 'months').endOf('month');

  const handleDateChange = (newValue, key) => {
    const newDate = moment(newValue);
    setCalendarPeriod(prevCalendarPeriod => ({ ...prevCalendarPeriod, [key]: newDate }));
    setIsDirty(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsDirty(false);
  };

  const handleApply = () => {
    dataProvider.setFilter(prevFilter => ({
      ...prevFilter,
      start: moment(calendarPeriod.start).format('YYYY-MM-DD'),
      end: moment(calendarPeriod.end).format('YYYY-MM-DD'),
    }));

    setAnchorEl(null);
    setIsDirty(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
        <Button aria-describedby={id} variant="outlined" onClick={handleClick} className="filter-btn">
          <img src="/assets/icons/ic_calendar.svg" className="calendar-icon" />
          {formatDate(dataProvider.filter.start)}
          <Box sx={{ width: '8px', margin: '0.5rem' }}>
            <Divider />
          </Box>
          {formatDate(dataProvider.filter.end)}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Box sx={{ p: 2, pb: 0, display: 'flex', flexFlow: 'column', alignItems: 'center', background: variables.lightGrayBackground }}>
            <Box className='calendar-wrapper'>
              <Box sx={{ margin: '0 30px' }}>
                <Typography sx={{ fontSize: '14px', color: variables.greenPrimary }}>Start Date</Typography>
                <Typography sx={{ fontWeight: '600' }}>{formatDate(calendarPeriod.start)}</Typography>
              </Box>
              <Typography>to</Typography>
              <Box sx={{ margin: '0 30px' }}>
                <Typography sx={{ fontSize: '14px', color: variables.greenPrimary }}>End Date</Typography>
                <Typography sx={{ fontWeight: '600' }}>{formatDate(calendarPeriod.end)}</Typography>
              </Box>
            </Box>
            <Box className='calendar-wrapper'>
              <ToggleButtonGroup
                value={calendarView}
                exclusive
                color="primary"
                onChange={handleViewChange}
                sx={{ height: '35px' }}
              >
                {isWeeklyGranularity ? ['day', 'month', 'year'].map(view => (
                  <ToggleButton key={view} value={view}>{view}</ToggleButton>
                )) : ['month', 'year'].map(view => (
                  <ToggleButton key={view} value={view}>{view}</ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
            <Box className='calendar-wrapper'>
              <DateCalendar
                showDaysOutsideCurrentMonth
                displayWeekNumber
                value={calendarPeriod.start}
                view={calendarView}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(newValue) => handleDateChange(newValue, 'start')}
                sx={{
                  margin: 0,
                  '.MuiPickersDay-root.Mui-disabled': {
                    color: variables.midGraySecondary + ' !important',
                  },
                  '.Mui-disabled': {
                    textDecoration: 'line-through',
                  },
                  '.MuiPickersCalendarHeader-switchViewButton': {
                    display: 'none',
                  },
                  '.Mui-selected': {
                    color: variables.darkGrayPrimary + ' !important',
                    '&:hover, &:focus': {
                      backgroundColor: variables.greenPrimary + ' !important',
                    }
                  }
                }}
              />
              <DateCalendar
                showDaysOutsideCurrentMonth
                displayWeekNumber
                value={calendarPeriod.end}
                view={calendarView}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(newValue) => handleDateChange(newValue, 'end')}
                sx={{
                  '.MuiPickersDay-root.Mui-disabled': {
                    color: variables.midGraySecondary + ' !important',
                  },
                  '.Mui-disabled': {
                    textDecoration: 'line-through',
                  },
                  '.MuiPickersCalendarHeader-switchViewButton': {
                    display: 'none',
                  },
                  margin: 0,
                  '.Mui-selected': {
                    color: variables.darkGrayPrimary + ' !important',
                    '&:hover, &:focus': {
                      backgroundColor: variables.greenPrimary + ' !important',
                    }
                  }
                }}
              />
            </Box>
          </Box>
          <Box sx={{ p: 2, pt: 0, display: 'flex', justifyContent: 'end', background: variables.lightGrayBackground }}>
            <Button
              variant="outlined"
              onClick={handleApply}
              disabled={!(isDirty && calendarPeriod.end.isAfter(calendarPeriod.start))}
            >
              Apply
            </Button>
          </Box>
        </Popover>
      </Box>
    </LocalizationProvider>
  )
}

function DataFilterSelector({ label, filter }) {
  const dataProvider = useDataProvider();

  useEffect(() => {
    const newFilter = {
      ...dataProvider.filter, path: `sets/orders`,
    };
    dataProvider.setFilter(newFilter);
  }, []);

  const items = dataProvider?.data?.dataSets?.countryList ?? ['all'];

  const handleChange = (event) => {
    const newFilter = { ...dataProvider.filter };
    newFilter[filter] = event.target.value;
    dataProvider.setFilter(newFilter);
  };

  const renderValue = (selectedValue) => {
    return <Typography sx={{ fontSize: '12px', fontWeight: '600', }}>
      <span style={{ fontWeight: '400', color: variables.lightGraySecondary }}>Country: {selectedValue}</span>
    </Typography>;
  };

  return (
    <FormControl
      sx={{
        '.MuiInputBase-root': {
          background: variables.darkGraySecondary,
          borderRadius: '4px',
          minWidth: '120px',
          height: '38px',
        }
      }} size="small">
      <Select
        sx={{
          '.MuiInputBase-input': {
            display: 'flex',
            alignItems: 'center',
          },
          '.MuiSvgIcon-root': {
            color: 'primary.main',
          }
        }}
        size='small'
        value={dataProvider.filter[filter].toUpperCase()}
        onChange={handleChange}
        renderValue={renderValue}
        IconComponent={KeyboardArrowDownIcon}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item === 'all' ? 'All' : item.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}