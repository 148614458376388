import { SaveAltRounded } from "@mui/icons-material";
import { CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import { useState } from "react";

export function EditableTypography({title, loading, onSaveAsync}) {
    const [editMode, setEditMode] = useState(false);
    const [text, setText] = useState(title);
    const [error, setError] = useState(false);

    const handleSave = () => {
        if(text.length == 0)    
            return setError(true);
        
        onSaveAsync(text).then(() => { setEditMode(false); setError(false); });
    }

    return (
    <>
        {
            !editMode && <>
                <HoverTypography onClick={() => setEditMode(true)}>
                    {text.charAt(0).toUpperCase() + text.slice(1)}
                </HoverTypography>
            </>
        }
        {
            editMode && <>
                <TextField 
                    onKeyDown={(ev) => { if(ev.key == "Enter") handleSave() }}
                    error={error}
                    value={text}
                    variant={"outlined"} 
                    onChange={(ev) => setText(ev.currentTarget.value)}
                    size={"small"}
                    InputProps={{
                        style: {
                            fontSize: "18px"
                        },
                        endAdornment:
                        <>
                        {
                            !loading  && 
                            <IconButton onClick={handleSave}>
                                <SaveAltRounded></SaveAltRounded>
                            </IconButton>
                        } 
                        {
                            loading && 
                             <CircularProgress size={20} sx={{display:"inherit"}}></CircularProgress>
                        }
                        </>
                    }}
                >
                </TextField>
            </>
        }
        
    </>
    )
}

const HoverTypography = ({onClick, children}) => {
    const [state, setState] = useState({
        border: '2px solid transparent',
        borderRadius: '10px',
        borderOpacity: '0.5',
        padding: '0 10px',
        display: 'inline-block',
        transition: 'border 0.3s ease-in-out',
        cursor: 'pointer',
        fontSize: '18px'
    });
    
    const handleMouseEnter = () => {
        setState({...state, border: '1px solid'})
    };
  
    const handleMouseLeave = () => {
        setState({...state, border: '1px solid transparent'});
    };
  
    return (
      <Typography
        style={state}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      >
        {children}
      </Typography>
    );
  };

