import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import Cookies from "js-cookie";
import clientApi from '../client/client';
import { SettingTypes } from '../shared/enums';
import { GlobalActionTypes } from '../redux/globals';
import { useDispatch } from 'react-redux';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      try {
        const authUser = await Auth.currentAuthenticatedUser();
        const groups = authUser?.signInUserSession?.accessToken?.payload['cognito:groups'] ?? [];
        if (groups.includes('admin')) {
          authUser.isAdmin = true;
        }
        if (groups.includes('demo')) {
          authUser.isDemo = true;
        }
        setUser(authUser);
        await refreshToken();
      } catch (err) {
        console.log('User not signed in', err);
        setUser(null);
      }
    };

    checkUser();

    const hubListener = Hub.listen('auth', async ({ payload: { event } }) => {
      if (event === 'signIn' || event === 'customOAuthState') {
        await checkUser();
      }
    });

    return () => {
      Hub.remove('auth', hubListener);
    };
  }, []);

  const refreshToken = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const idToken = currentSession.getIdToken().getJwtToken();
      Cookies.set('user_id_token', idToken);
      await loadGlobals();
    } catch (err) {
      console.error('Error refreshing token:', err);
    }
  };

  const loadGlobals = async () => {
    try {
      const settings = await clientApi().app.settings.list([
        SettingTypes.environment,
        SettingTypes.currency,
        SettingTypes.profile,
        SettingTypes.first_auth,
        SettingTypes.company_info,
      ]);

      dispatch({ type: GlobalActionTypes.USER_SETTINGS, payload: settings });
      dispatch({ type: GlobalActionTypes.IMPERSONATED_USER_SETTINGS, payload: settings });
    } catch (err) {
      console.error('Error loading global settings:', err);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      setUser(null);
      Cookies.remove('user_id_token');
      localStorage.removeItem('eleni.user.settings');
      dispatch({ type: GlobalActionTypes.IMPERSONATED_USER_SETTINGS, payload: [{ name: SettingTypes.currentUser, value: '' }] });
      navigate('/');
    } catch (err) {
      console.error('Error signing out:', err);
    }
  };

  const value = {
    getAccessToken: () => user?.signInUserSession?.accessToken?.jwtToken,
    user,
    loading,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
