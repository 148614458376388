import React, { createContext, useContext, useEffect, useState } from 'react';
import { Snackbar, Alert, Slide} from '@mui/material';
import { useAuth } from './useAuth';
import variables from 'src/styles/variables.scss';
import { API_URLS } from 'src/domain.config';
import { NotificationTypes } from 'src/shared/enums';


function SlideTransition(props) {   
    return <Slide {...props} direction="down" />;
}

const PushNotificationsContext = createContext();
export const usePushNotifications = () => useContext(PushNotificationsContext);

const PushNotificationsProvider = ({ children }) => {
  const {user, getAccessToken} = useAuth();
  const [notification, setNotification] = useState(null);
  const [open, setOpen] = useState(false);

  const handleNewNotification = (message) => {
    setNotification(message);
    setOpen(true);
  };

  useEffect(() => {
    try {
        const ws = new WebSocket(`${API_URLS.NOTIFICATIONS}?token=${getAccessToken()}`);
      
        ws.onmessage = (event) => { 
            console.log("message retrieved", event);
            try {
                const eventData = JSON.parse(event.data);
                if(NotificationTypes[eventData.code])
                    handleNewNotification(NotificationTypes[eventData.code].message); 
            } catch(err) {
                console.log("error parsing notification");
            }
        };
        console.log("connected to", API_URLS.NOTIFICATIONS);
        ws.onclose = () => {};

        ws.onopen = () => {};

        return () => {
            ws.close();
        };
    } catch(err) {
        console.log(err);
    }
  }, []);

  const handleClose = () => {
    setOpen(false)
  };

  return (
    <PushNotificationsContext.Provider value={{ notification }}>
      {children}
      <Snackbar
        security='warning'
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert 
            onClose={handleClose} 
            severity='success' 
            variant="outlined" 
            sx={{color:"white", backgroundColor: variables.darkGrayPrimary}}
        >
            {notification}
        </Alert>
      </Snackbar>
    </PushNotificationsContext.Provider>
  );
};

export default PushNotificationsProvider;

// Custom hook to access notifications
export const useNotifications = () => {
  const { notification } = usePushNotifications();
  return { notification };
};