import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import Page404 from './pages/Page404';
import LoadingPage from './pages/LoadingPage';
import { useAuth } from './hooks/useAuth';
import ConnectorsPage from './pages/connectors/page';
import AccountSettingsPage from './pages/account/settings';
import AccountSecurityPage from './pages/account/security';
import AccountDashboardSettings from './pages/account/dashboard';
import PerformanceKpisPage from './pages/performanceKpis/page';
import { AdminUserPage } from './pages/admin/user/page';
import CashflowPage from './pages/cashflow/page';
import FullWidthComponent from './components/FullWidthComponent';
import ImportHistoryPage from './pages/admin/import/page';

export default function Router() {
  const { user, loading } = useAuth();

  const baseRoutes = [
    { path: 'overview', element: <CashflowPage /> },
    { path: "overview/:widgetType", element: <FullWidthComponent /> },
    { path: 'performance-kpis', element: <PerformanceKpisPage /> },
    { path: "performance-kpis/:widgetType", element: <FullWidthComponent /> },

    { path: 'profile-settings', element: <AccountSettingsPage /> },
    { path: 'security-settings', element: <AccountSecurityPage /> },
    // { path: 'account/dashboard', element: <AccountDashboardSettings /> },

    { path: 'connectors', element: <ConnectorsPage /> },

    { path: '404', element: <Page404 /> },
    { path: "/", element: <Navigate to="/overview" /> },
    { path: "*", element: <Navigate to="/404" /> },
  ];

  const adminRoutes = [
    // { path: 'admin', element: <AdminDashboardPage /> },
    { path: 'admin/user', element: <AdminUserPage /> },
    { path: 'admin/user/import-history', element: <ImportHistoryPage /> },
  ];

  const loadingRoute = [{
    path: "/",
    children: [
      { path: '*', element: <LoadingPage /> },
    ],
  }];

  let routes = loadingRoute;

  if (loading === false && user) {
    const userRoutes = [{
      path: "/",
      element: <DashboardLayout />,
      children: [
        ...baseRoutes,
        ...((user.isAdmin || user.isDemo) ? adminRoutes : []), // Conditionally add admin routes
      ],
    }];

    routes = userRoutes;
  }

  return useRoutes(routes);
}
