import { styled } from '@mui/material/styles';
import { Input, Button, InputAdornment, } from '@mui/material';

import Iconify from '../../../components/iconify';
import variables from 'src/styles/variables.scss';

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 70;

const StyledSearchbar = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  paddingLeft: '1rem',
  margin: '0 1rem',
  border: '1px solid rgba(145, 158, 171, 0.32)',
  borderRadius: '4px',
  background: variables.darkGraySecondary,
  opacity: '80%',
  [theme.breakpoints.up('lg')]: {
    minWidth: '720px',
  },
}));

export default function CustomSearchBar() {

  return (
    <StyledSearchbar>
      <Input
        autoFocus
        fullWidth
        disableUnderline
        placeholder="Search for anything or type commands like “Show me burn rate forecast for next quarter”"
        startAdornment={
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{
                color: variables.lightGraySecondary,
                width: 20,
                height: 20
              }}
            />
          </InputAdornment>
        }
        sx={{
          mr: 1,
          fontWeight: '400',
          color: variables.lightGraySecondary,
        }}
      />
      <Button
        onClick={() => {}}
        sx={{
          color: variables.lightGraySecondary,
          minWidth: '40px',
          padding: 0,
          mr: '0.5rem',
          background: variables.darkGrayPrimary,

          '&:hover': {
            backgroundColor: variables.greenBackground,
          }
        }}
      >
        <img src="/assets/icons/ic_search.svg" alt="search button icon" style={{ paddingRight: '4px' }} /> E
      </Button>
    </StyledSearchbar>
  );
}
