import { Alert, Slide, Snackbar } from "@mui/material";
import { createContext, useContext, useState } from "react";
import { ErrorCodes } from "src/shared/errors";
import variables from 'src/styles/variables.scss';

const SnackbarContext = createContext();

function SlideTransition(props) {   
    return <Slide {...props} direction="down" />;
}


export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('error');

  const messageFromCode = (err) => {
    let message = err?.message ?? err ?? "unknown_error";
    message = ErrorCodes[message].message ?? message;
   
    message = message.trim().replaceAll("_", " ");
    message = message.charAt(0).toUpperCase() + message.slice(1);

    return message;
  }

  const openError = (err) => {
    const _message = messageFromCode(err);
    setSeverity('error');
    setMessage(_message);
    setOpen(true);
  };

  const openSuccess = (message) => {
    setSeverity('success');
    setMessage(message);
    setOpen(true);
  };

  return (
    <SnackbarContext.Provider value={{ openError, openSuccess }}>
      {children}
      {open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={5000}
          onClose={() => setOpen(false)}
          message={message}
          TransitionComponent={SlideTransition}
        >
            <Alert variant={"outlined"} severity={severity} sx={{color: "black", background: variables.lightGrayPrimary}}>{message}</Alert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
};