import React from 'react';
import { Card, Typography, Box, Divider } from '@mui/material';
import { DataFilterGranularities } from "src/shared/enums";
import variables from 'src/styles/variables.scss';
import 'src/styles/styles.scss';

const cardStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',
    height: '130px',
    background: variables.darkGraySecondary,
    padding: '1.5rem',
    borderRadius: '4px',
    boxShadow: 'none',
}

const GradientDivider = () => (
    <Divider sx={{
        background: 'linear-gradient(to right, #1F232C, #353C4B)',
        height: '2px',
        border: 'none',
    }} />
);

export default function CashflowCard({ id, titleNow, valueNow, valueForecast, diffNow, diffForecast, percentageDiffNow, percentageDiffForecast, currency, fullscreen, granularity }) {
    const [integerPartNow, decimalPartNow] = valueNow.toLocaleString('us-US').split('.');
    const [integerPartForecast, decimalPartForecast] = valueForecast.toLocaleString('us-US').split('.');

    return (
        <Card sx={{ ...cardStyles, justifyContent: fullscreen && 'center', }}>
            <Box sx={{ display: 'flex' }}>
                <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                        <span style={{ fontWeight: 600 }}>{titleNow}</span>
                        {granularity === DataFilterGranularities.weekly ? 'This Week' : 'This Month'}
                    </Typography>
                    <Typography sx={{ fontSize: '24px', fontWeight: 600, alignItems: 'baseline', }}>
                        {currency + ' ' + integerPartNow}
                        {decimalPartNow &&
                            <span style={{ fontSize: '14px', fontWeight: 500 }}>.{decimalPartNow}</span>
                        }
                        <span
                            style={{
                                fontSize: '12px',
                                color: diffNow > 0 ? variables.greenPrimary : diffNow < 0 ? variables.redPrimary : variables.lightGrayPrimary,
                                display: 'flex',
                                alignItems: 'center',
                                lineHeight: 3,
                            }}
                        >
                            <img
                                src={diffNow < 0 ? "/assets/icons/ic_stats_arrow_down.svg" : "/assets/icons/ic_stats_arrow_up.svg"}
                                style={{ padding: '0 0.5rem 0 0' }}
                            />
                            {percentageDiffNow}%
                            <span style={{ color: variables.lightGrayPrimary, fontWeight: 400, padding: '0 0.5rem' }}>
                                vs. last {granularity === DataFilterGranularities.weekly ? ' week' : ' month'}
                            </span>
                        </span>
                    </Typography>
                </Box>
                <Box sx={{ width: '56px', margin: '2rem' }}>
                    <GradientDivider />
                </Box>
                <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: 400, display: 'flex' }}>
                        Next {granularity === DataFilterGranularities.weekly ? ' Week ' : ' Month '} Forecast
                        <img src="/assets/icons/ic_forecast.svg" style={{ paddingLeft: '0.5rem' }} />
                    </Typography>
                    <Typography sx={{ fontSize: '24px', fontWeight: 600, alignItems: 'baseline', }}>
                        {currency + ' ' + integerPartForecast}
                        {decimalPartForecast &&
                            <span style={{ fontSize: '14px', fontWeight: 500 }}>.{decimalPartForecast}</span>
                        }
                        <span
                            style={{
                                fontSize: '12px',
                                color: diffForecast > 0 ? variables.greenPrimary : diffForecast < 0 ? variables.redPrimary : variables.lightGrayPrimary,
                                display: 'flex',
                                alignItems: 'center',
                                lineHeight: 3,
                            }}
                        >
                            <img
                                src={diffForecast < 0 ? "/assets/icons/ic_stats_arrow_down.svg" : "/assets/icons/ic_stats_arrow_up.svg"}
                                style={{ padding: '0 0.5rem 0 0' }}
                            />
                            {percentageDiffForecast}%
                            <span style={{ color: variables.lightGrayPrimary, fontWeight: 400, padding: '0 0.5rem' }}>
                                vs. this {granularity === DataFilterGranularities.weekly ? ' week' : ' month'}
                            </span>
                        </span>
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
}