import { useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import variables from 'src/styles/variables.scss';
import { useTheme, Button, Dialog, DialogContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography, DialogActions, IconButton, Stack, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { SettingTypes } from 'src/shared/enums';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalActionTypes } from 'src/redux/globals';
import { arrayToObject } from 'src/shared/utils';
import CustomSearchBar from 'src/components/CustomSearchBar';
import clientApi from 'src/client/client';

const StyledPicker = styled('div')(() => ({
    width: '395px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '32px',
    paddingLeft: '1rem',
    margin: '0 1rem',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    borderRadius: '4px',
    background: variables.darkGraySecondary,
}));

const usersList = [
    {
        "id": "8e9c63de-3364-4fe5-892e-5db2839f13e6",
        "email": "echazan@collegia.co.uk",
        "alternateIds": []
    },
    {
        "id": "581db386-482a-43d0-959c-6f2ac66346a9",
        "email": "teomindrusca@gmail.com",
        "phone": "+400742864067",
        "alternateIds": []
    },
    {
        "id": "6229cc1f-386d-470c-9d55-872210f851c4",
        "email": "marius.jumolea@greppysystems.com",
        "phone": "+40748028172",
        "alternateIds": [
            "google_114240114601401515967"
        ]
    },
    {
        "id": "d6402a82-64c6-454f-9bd5-e40e7c73efa0",
        "email": "marius.jumolea+1@greppysystems.com",
        "phone": "+40748028172",
        "alternateIds": []
    },
    {
        "id": "67c9fbac-8431-43b6-bf9e-5b3e4177d9b2",
        "email": "info@flyaround.ro",
        "alternateIds": []
    },
    {
        "id": "80b7d63e-7709-45e1-896c-99448cd78137",
        "email": "julian.mensah@voltric.co.uk",
        "alternateIds": []
    },
    {
        "id": "37eb817e-179f-4330-ad69-cb51cae273d3",
        "email": "alina@tamzenconsulting.com",
        "alternateIds": []
    },
    {
        "id": "750bf681-6043-4e75-a0ee-e4fc7a4bd669",
        "email": "brent.oldfield@voltric.co.uk",
        "alternateIds": []
    },
    {
        "id": "4168c419-8f3e-4a19-8eb0-76865bc2d04c",
        "email": "tatar.mihai@gmail.com",
        "alternateIds": []
    },
    {
        "id": "ed14bd62-afde-42de-bca2-2ffe2bf192cd",
        "email": "elenidemo@eleni.finance",
        "alternateIds": []
    }
]

export default function UserPicker() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const globalSettings = arrayToObject(useSelector(state => state.impersonated_settings));

    useEffect(() => {
        const fetchUsers = async () => {
            const result = await clientApi().app.admin.users();
            // const result = usersList;
            setUsers(result || []);
        };

        const storedUser = localStorage.getItem('selectedUser');

        if (storedUser) {
            const user = JSON.parse(storedUser);
            if (globalSettings?.currentUser?.id !== user?.id)
                dispatch({ type: GlobalActionTypes.IMPERSONATED_USER_SETTINGS, payload: [{ "name": SettingTypes.currentUser, "value": { email: user.email, id: user.id } }] });
        }

        fetchUsers();
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSelectUser = async (user) => {
        dispatch({ type: GlobalActionTypes.IMPERSONATED_USER_SETTINGS, payload: [{ "name": SettingTypes.currentUser, "value": { "email": user.email, "id": user.id } }] });
        localStorage.setItem('selectedUser', JSON.stringify({ email: user.email, id: user.id }));
        handleClose();
    };

    const ObjectTableCellStyle = (theme, header = false) => {
        return {
            border: 1,
            borderColor: variables.darkGraySecondary,
            background: header ? variables.darkGrayPrimary : variables.darkGraySecondary,
            color: "white",
            padding: 1
        }
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const renderUserList = () => {
        const filteredUsers = users.filter(user =>
            user.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (user.phone && user.phone.toLowerCase().includes(searchQuery.toLowerCase()))
        );

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={ObjectTableCellStyle(theme, true)}>ID</TableCell>
                            <TableCell sx={ObjectTableCellStyle(theme, true)}>Email</TableCell>
                            <TableCell sx={ObjectTableCellStyle(theme, true)}>Phone</TableCell>
                            <TableCell sx={ObjectTableCellStyle(theme, true)}>Select</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell sx={ObjectTableCellStyle(theme, false)}>{user.id}</TableCell>
                                <TableCell sx={ObjectTableCellStyle(theme, false)}>{user.email}</TableCell>
                                <TableCell sx={ObjectTableCellStyle(theme, false)}>{user.phone}</TableCell>
                                <TableCell sx={ObjectTableCellStyle(theme, false)}>
                                    <Button onClick={() => handleSelectUser(user)}>Select</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    };

    return (
        <>
            <StyledPicker>
                <Typography sx={{ color: variables.lightGrayPrimary, fontSize: '12px' }}>
                    Client: {globalSettings?.currentUser?.email || 'Select a user'}
                </Typography>
                <Button sx={{ fontSize: '12px' }} onClick={handleOpen}>
                    Change
                </Button>
            </StyledPicker>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
                sx={{
                    '.MuiPaper-root': {
                        minHeight: '560px',
                        backgroundColor: variables.darkGraySecondary,
                    }
                }}
            >
                <DialogActions>
                    <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                </DialogActions>
                <DialogContent>
                    <Stack sx={{ height: "90%" }} justifyContent={"center"} alignItems={"center"}>
                        <Typography
                            mb={5}
                            sx={{
                                fontSize: '20px',
                                fontWeight: 400,
                            }}
                        >
                            Select user
                        </Typography>
                        <CustomSearchBar searchQuery={searchQuery} onSearchChange={handleSearchChange} placeholder={'Search by ID, email or phone number'}></CustomSearchBar>
                        {renderUserList()}
                    </Stack>
                </DialogContent>

            </Dialog>
        </>
    );
}
