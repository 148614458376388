import { Box, Typography } from "@mui/material";
import variables from 'src/styles/variables.scss';

export default function ComingSoon() {
    return (
        <Box sx={{
            backgroundColor: '#25272F',
            height: '18px',
        }}>
            <Typography sx={{
                textTransform: 'uppercase',
                color: variables.lightGrayPrimary,
                fontSize: '10px',
                fontWeight: 600,
                p: '2px 4px'
            }}>
                Coming soon
            </Typography>
        </Box>
    )
}