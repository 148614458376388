import Cookies from "js-cookie";
import { objectToUrlParams } from "src/shared/utils";
import { API_URLS } from "src/domain.config";
import { ApiError, ErrorCodes } from "src/shared/errors";


const _request = async (method, key, params, data) => {
    const accessToken = Cookies.get('user_id_token');

    const type = { method };

    const header = {
        headers: (method === "PUT") ? {
            "Authorization": "Bearer " + accessToken,
        } : {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "application/json"
        }
    }

    const body = {
        body: (method === "PUT") ? data : JSON.stringify(data)
    };

    const queryParams = objectToUrlParams(params);

    const apiUrl = API_URLS.APP;

    const url = queryParams ? `${apiUrl}${key}?${queryParams}` : `${apiUrl}${key}`;
    // console.log(url)
    const response = await fetch(
        url,
        method !== "GET" ? { ...type, ...header, ...body } : { ...type, ...header }
    ).catch((err) => {
        throw new ApiError(ErrorCodes.error_retrieving_data.code);
    });

    if (!response.ok)
        throw new ApiError(ErrorCodes.error_unknown.code);

    const responseText = await response.text(); // First get the response as text

    // Check if the response text is not empty and parse it
    const responseData = responseText ? JSON.parse(responseText) : {};

    // Check for error codes in the response data
    if (responseData.code)
        throw new ApiError(ErrorCodes[responseData.code].code);

    return responseData;
}

const getData = async (key, params) => {
    return _request("GET", key, params);
}

const postData = async (key, params, data) => {
    return _request("POST", key, params, data);
}

const putData = async (key, params, data) => {
    return _request("PUT", key, params, data);
}

const deleteData = async (key, params, data) => {
    return _request("DELETE", key, params, data);
}

export const request = {
    getData,
    postData,
    putData,
    deleteData
}

