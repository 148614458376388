import React, { createContext, useContext, useEffect, useState } from 'react';
import clientApi from 'src/client/client';
import { arrayToObject, mergeArraysByKey } from 'src/shared/utils';
import { useAuth } from './useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalActionTypes } from 'src/redux/globals';

export const UserSettingsContext = createContext();

const storage_key = "eleni.user.settings";

const storage = {
  storedValues: (keys) => {
    const storedValues = JSON.parse(localStorage.getItem(storage_key)) ?? [];
    return storedValues.map((setting) => {
      return keys.includes(setting.name) ? setting : null;
    }).filter(s => s != null);
  },

  store: (values) => {
    const storedValues = JSON.parse(localStorage.getItem(storage_key)) ?? [];
    localStorage.setItem(storage_key, JSON.stringify(mergeArraysByKey(storedValues, values, "name")));
  },

  destroy: (key) => {
    const storedValues = JSON.parse(localStorage.getItem(storage_key)) ?? [];
    const newValues = storedValues.filter((setting) => setting.name != key);
    localStorage.setItem(storage_key, JSON.stringify(newValues));

    return newValues
  }
}

export const UserSettingsProvider = ({ children, keys }) => {
  const { user, loading: loadingAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const globalSettings = arrayToObject(useSelector(state => state.settings));
  const impersonatedSettings = arrayToObject(useSelector(state => state.impersonated_settings));
  // const [settings, setSettings] = useState(storage.storedValues(keys));
  // const [selectedUser, setSelectedUser] = useState(null);

  // const get = (key) => {
  //   const setting = settings?.find((setting) => setting.name == key)?.value ?? null;
  //   return setting;
  // }

  const fetchFromCache = async (keys, userToFetch) => {
    // const storedValues = storage.storedValues(keys);
    // const storedKeys = storedValues?.map((setting) => setting.name) ?? [];
    // const requiredKeys = userToFetch ? keys : keys.filter((key) => !storedKeys.includes(key));

    const filterUserId = userToFetch?.id ?? null;
    const filterUserEmail = userToFetch?.email ?? null;
    ;
    const settings = keys?.length > 0 ? await clientApi().app.settings.list(keys, filterUserId, filterUserEmail).catch(err => { }).finally(() => { }) : [];

    dispatch({ type: GlobalActionTypes.IMPERSONATED_USER_SETTINGS, payload: settings });
    console.log(settings)
    return settings;
  }

  const fetch = async (userToFetch) => {
    setLoading(true);
    await fetchFromCache(keys, userToFetch);
    setLoading(false);
  }

  const isReady = () => {
    return loading == false;
  }

  const set = async (key, value) => {
    setLoading(true)
    await clientApi().app.settings.set(key, value).catch(err => {}).finally(() => {
      storage.destroy(key);
      fetch();
    });
  }

  useEffect(() => {
    if (user?.isAdmin && impersonatedSettings?.currentUser) {
    const userIdToFetch = user.isAdmin && impersonatedSettings?.currentUser ? impersonatedSettings?.currentUser : null;
    fetch(userIdToFetch);
    }
  }, [user, impersonatedSettings?.currentUser]);

  return (
    <UserSettingsContext.Provider value={{ set, isReady, loading }}>
      {
        React.Children.map(children, child => {
          return React.cloneElement(child, {
            disabled: !isReady()
          });
        })
      }
    </UserSettingsContext.Provider>
  );
}

export const useUserSettings = () => {
  const context = useContext(UserSettingsContext);

  if (context === undefined) {
    throw new Error('useUserSettings must be used within a UserSettingsProvider');
  }

  return context;
};