import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import variables from 'src/styles/variables.scss';
import Logo from '../logo/Logo';

NavSectionMobile.propTypes = {
  data: PropTypes.array,
};

export default function NavSectionMobile({ data = [], isDesktop, ...other }) {
  return (
    <List
      disablePadding
      sx={{
        p: 2,
      }}
    >
      <Box sx={{ p: 1, display: 'inline-flex' }}>
      <Logo sx={{ width: 84, height: 24 }} src={"/assets/icons/navbar/ic_logo_lg.svg"} />
      </Box>
      {data.map((item) => (
        <NavItem key={item.title} item={item} />
      ))}
    </List>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        width: 144,
        color: variables.lightGrayPrimary,
        fontWeight: 500,
        marginTop: '1rem',
        transition: 'all 0.2s ease',
        borderRadius: '4px',
        '&.active': {
          color: variables.greenPrimary,
          background: variables.midGrayPrimary,
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText primary={title} />
    </StyledNavItem>
  );
}
