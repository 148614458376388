import React from 'react';
import {  Container, } from '@mui/material';
import { ConnectorsList } from './components/ConnectorsList';

export default function ConnectorsPage() {
    
  return (
    <Container sx={{maxWidth:"850px !important"}}>
        <ConnectorsList/>
    </Container>  
  );
}