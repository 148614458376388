import { Alert, Box, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import clientApi from "src/client/client";
import { ConnectorTypes } from "src/shared/enums";
import { arrayToObject } from "src/shared/utils";
import variables from 'src/styles/variables.scss';

export default function ErrorBanner() {
    const [error, setError] = useState({
        message: '',
        button: '',
        path: ''
    });

    const settings = arrayToObject(useSelector(state => state.impersonated_settings));
    const userId = settings.currentUser?.id ?? null;

    useEffect(() => {
        const checkConnectionsStatus = async () => {
            const params = userId ? { user: userId } : {}; // Add 'user' to params only if it's not null
            const connectors = await clientApi().app.connectors.list(params).catch((err) => { });
            console.log(connectors);
            connectors?.map((connector) => {
                if (connector?.type === ConnectorTypes.SALTEDGE_PARTNERS_V1 || connector?.type === ConnectorTypes.SALTEDGE_V6) {
                    //stupid patching
                    if (connector.accountId?.error?.class === 'ConnectionNotFound' ||
                        connector.consentInfo?.error?.class === 'CustomerNotFound') {
                        return;
                    }
                    const activeConsent = connector?.consentInfo?.data?.find(c => c.status === 'active');
                    const activeConnection = connector?.connectionInfo?.data?.status === 'active';
                    if (!activeConsent || !activeConnection) {
                        setError({
                            message: 'You have one or more inactive data connections.',
                            button: 'View and reconnect',
                            path: 'connectors'
                        });
                    }
                }
            });
        };

        checkConnectionsStatus();
    }, [userId]);

    if (error.message === '') {
        return <Box></Box>;
    }

    return (
        <Alert
            severity="error"
            sx={{
                display: 'flex',
                width: '100%',
                m: '0 1rem !important',
                flexFlow: 'row',
                height: '40px',
                maxWidth: '700px',
                alignItems: 'center',
                border: '1px solid ' + variables.redPrimary,
                '.MuiAlert-icon': {
                    p: 0,
                },
                '.MuiAlert-message': {
                    p: 0
                }
            }}>
            <Typography>
                {error.message}
                <Link href={error.path} sx={{ color: 'black', textDecorationColor: 'black', pl: '1rem' }}>
                    {error.button}
                </Link>
            </Typography>

        </Alert>
    );
}