export const categoriesList = [
    'uncategorized',
    'operating_expenses',
    'investments',
    'borrowing',
    'taxes',
    'cost_of_sales',
    'forecasted',
    'stripe',
    'saltedge',
]

