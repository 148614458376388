import { useEffect, useState } from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Box, Typography, Menu, MenuItem, useTheme, Button, Tooltip, FormControl, RadioGroup, FormControlLabel, Radio, } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import 'src/styles/styles.scss';
import variables from 'src/styles/variables.scss';
import { useDataProvider } from 'src/hooks/useDataProvider';
import { CurrencieSymbols, DataFilterGranularities, TableIconTypes, TransactionsTypes } from "src/shared/enums";
import { iconComponents } from 'src/utils/tableIconMapping';

import { MoreVert } from '@mui/icons-material';
import AppDrawer from 'src/components/AppDrawer';
import { TransactionsProvider } from 'src/hooks/useTransactionsProvider';
import TransactionsTable from 'src/pages/transactions/components/transactions.table';
import { transformDate } from 'src/shared/utils';
import moment from 'moment';
import clientApi from 'src/client/client';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { useAuth } from 'src/hooks/useAuth';

const TableErrors = Object.freeze({
    cell_save: "cell_save"
});

const table_container_styles = {
    display: 'flex',
    position: 'relative',
    '&::-webkit-scrollbar': {
        height: '10px',
        cursor: 'pointer',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: variables.darkGrayPrimary,
        borderRadius: '8px',
        cursor: 'pointer',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: variables.greenPrimary,
        borderRadius: '8px',
        cursor: 'pointer',
        backgroundColor: '#7F89A1',
    },
}

export default function DataTable({ dataProvider, type, editData, loadMoreData }) {
    const snackbar = useSnackbar();
    const [selectedCellKey, setSelectedCellKey] = useState(null);
    const [onEdit, setOnEdit] = useState({
        currentKey: null,
        currentValue: null,
        newValue: null,
        symbol: null,
        error: false,
    });
    const [storeOption, setStoreOption] = useState('add'); //'add' or 'overwrite'

    const headers = dataProvider?.data?.table ? dataProvider?.data?.table?.meta?.columns : dataProvider?.data?.meta?.columns ?? [];
    const rows = dataProvider?.data?.table ? dataProvider?.data?.table?.meta?.row_groups : dataProvider?.data?.meta?.row_groups ?? [];
    const values = dataProvider?.data?.table ? dataProvider?.data?.table?.values : dataProvider?.data?.values ?? [];
    const formattedHeaders = transformDates(headers) ?? [];

    const handleActionPerformed = (action) => {
        setSelectedCellKey(action.key);
    }

    const loadPreviousData = () => {
        dataProvider.setFilter(prevFilter => ({
            ...prevFilter,
            start: moment(dataProvider.filter.end).subtract(23, 'months').format('YYYY-MM-DD'),
            end: moment(dataProvider.filter.end).subtract(12, 'months').format('YYYY-MM-DD')
        }));
    };

    const loadNextData = () => {
        dataProvider.setFilter(prevFilter => ({
            ...prevFilter,
            start: moment(dataProvider.filter.end).add(1, 'months').format('YYYY-MM-DD'),
            end: moment(dataProvider.filter.end).add(12, 'months').format('YYYY-MM-DD')
        }));
    };

    function transformDates(headers) {
        return headers?.map(header => {
            if (header.includes('W')) {
                // For weekly granularity, parse the header as ISO week and get the first day of the week (Monday)
                return moment(header, 'YYYY-[W]WW').startOf('isoWeek').format("[w]WW MMM DD");
            } else {
                // For monthly granularity, parse the header as month and get the first day of the month
                return moment(header, 'MMM YYYY').startOf('month').format("MMM 'YY");
            }
        });
    }

    const handleStoreOptionSubmit = async () => {
        const result = await clientApi().data.store("data/sets/actuals", {
            "key": onEdit.currentKey,
            "value": onEdit.newValue,
            "currency": dataProvider.filter.currency,
            "environment": dataProvider.filter.environment,
            "granularity": dataProvider.filter.granularity,
            "overwrite": storeOption === "add" ? false : true,
            "checked": true
        }).catch((err) => {
            snackbar.openError(err.code)
        });

        if (result) {
            setOnEdit({ ...onEdit, error: false });
            snackbar.openSuccess("Value saved");
            const newFilter = { ...dataProvider.filter, path: `sets/transactions` };
            dataProvider.setFilter(newFilter);
        }
    };

    return (
        <>
            <TableContainer component={Paper} sx={table_container_styles}>
                {headers.length > 0 && loadMoreData &&
                    <Tooltip title="Show previous 12 periods" placement="left">
                        <Button className='load-btn load-prev-btn' onClick={loadPreviousData}><ArrowBackIosNewIcon /></Button>
                    </Tooltip>
                }
                <Table aria-label="collapsible table" sx={{ width: 'auto' }}>
                    <TableHead>
                        <TableRow>
                            <Box className='table-cell-title-wrapper'>
                                <TableCell sx={{ paddingLeft: 16 }} className='table-cell-title table-header'></TableCell>
                            </Box>
                            {headers.map((header, index) => (
                                <TableCell key={header} className='table-cell table-header'>{formattedHeaders[index] ?? header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <ManagementTableRow
                                key={row.key}
                                row={row}
                                headers={headers}
                                values={values}
                                level={1}
                                onActionPerformed={handleActionPerformed}
                                editData={editData}
                                loadMoreData={loadMoreData}
                                type={type}
                                onError={(type, code, currentKey, currentValue, newValue, symbol) => {
                                    setOnEdit({ currentKey, currentValue, newValue, symbol, error: true })
                                }}
                            />
                        ))}
                    </TableBody>
                </Table>
                {headers.length > 0 && loadMoreData &&
                    <Tooltip title="Show next 12 periods" placement="left">
                        <Button className='load-btn' onClick={loadNextData}><ArrowForwardIosIcon /></Button>
                    </Tooltip>
                }
            </TableContainer>

            <AppDrawer open={selectedCellKey != null} onClose={() => setSelectedCellKey(null)} height={80}>
                {selectedCellKey && <ManagementTransactions cellKey={selectedCellKey} />}
            </AppDrawer>

            <AppDrawer
                open={onEdit.error} onClose={() => setOnEdit({ ...onEdit, error: false })}
                height={50}
                title="Choose the storing options"
            >
                <Typography>We found existing {type} for this period and category. Please choose how you want to store the new value.</Typography>
                <FormControl sx={{ m: 3 }}>
                    <RadioGroup value={storeOption} onChange={(ev) => setStoreOption(ev.currentTarget.value)}>
                        <FormControlLabel
                            value="add"
                            control={<Radio />}
                            label={
                                <Typography>Add up the new value to the existing one. New value will be:
                                    <span style={{ fontWeight: '600', color: variables.greenPrimary }}> {(parseFloat(onEdit.currentValue) + parseFloat(onEdit.newValue)).toFixed(2)} {onEdit.symbol}</span>
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            value="overwrite"
                            control={<Radio />}
                            label={
                                <Typography>Overwrite the existing value with the new one. New value will be:
                                    <span style={{ fontWeight: '600', color: variables.greenPrimary }}> {onEdit.newValue} {onEdit.symbol}</span>
                                </Typography>
                            }
                        />
                    </RadioGroup>
                </FormControl>
                <FormControl sx={{ display: 'flex' }}>
                    <Button variant='outlined' fullWidth onClick={handleStoreOptionSubmit}>
                        Confirm
                    </Button>
                </FormControl>
            </AppDrawer>
        </>
    )
}

function ManagementTransactions({ cellKey }) {
    const cellProperties = cellKey.split(":");

    if (cellProperties.length <= 2)
        return null;

    const filterConfig = {
        category_key: cellProperties[2],
        type: cellProperties[1].includes("cash_inflow") ? TransactionsTypes.income : TransactionsTypes.expense,
        period: transformDate(cellProperties[0])
    }

    return (
        <TransactionsProvider filterConfig={filterConfig}>
            <TransactionsTable></TransactionsTable>
        </TransactionsProvider>
    )
}

function ManagementTableRow({ row, headers, values, level, onActionPerformed, onError, type, editData, loadMoreData }) {
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);

    const isCollapsible = row.collapse !== false;
    const isLeafElement = (!row.children || row.children.length === 0) && level > 1;
    const showDetails = row?.showDetails;
    const IconComponent = row.icon && iconComponents[TableIconTypes[row.icon]];

    const formatHeader = (header) => {
        return header.toLowerCase().replace(/\s/g, '_');
    }

    const findValueForRowAndColumn = (key, header) => {
        return values.find(value => value.hasOwnProperty(`${formatHeader(header)}:${key}`))?.[`${formatHeader(header)}:${key}`];
    };

    const isCurrentMonth = (header) => {
        const currentMonth = moment().format('MMM_YYYY').toUpperCase(); // Format like "Mar_2023"
        return header.toUpperCase() === currentMonth;
    };

    const isCurrentWeek = (header) => {
        const currentWeek = `${moment().year()}-W${moment().isoWeek().toString().padStart(2, '0')}`; // Format like "2023-W45"
        return header.toUpperCase() === currentWeek;
    };

    return (
        <>
            <TableRow className={`table-row ${isLeafElement ? 'leaf-element' : ''}`}>
                <Box className={'table-cell-title-wrapper'} sx={{ marginRight: !loadMoreData ? '0px' : '60px' }}>
                    <TableCell style={{ paddingLeft: 16 * level }} className={`${level !== 1 && 'child-title'} table-cell-title`}>
                        <Box className='cell-wrapper'>
                            <Box sx={{ display: 'flex' }}>
                                {IconComponent}
                                {row.name}
                            </Box>
                            {isCollapsible && (row.children && row.children.length > 0) && (
                                <IconButton
                                    aria-label="expand row"
                                    className='dropdown-icon title-icon'
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <KeyboardArrowDownIcon className='dropdown-icon dropdown-open-icon' /> : <KeyboardArrowRightIcon className='dropdown-icon' />}
                                </IconButton>
                            )}
                        </Box>
                    </TableCell>
                </Box>
                {headers.map(header => {
                    const valueData = findValueForRowAndColumn(row.key, header);
                    const cellKey = `${formatHeader(header)}:${row.key}`;
                    const isCurrentPeriod = dataProvider.filter.granularity === DataFilterGranularities.monthly ? isCurrentMonth(formatHeader(header)) : isCurrentWeek(formatHeader(header));
                    return (
                        <TableCell
                            key={cellKey}
                            className={`${isCurrentPeriod && 'current-cell'} table-cell`}
                            sx={{ color: valueData?.color && variables[valueData.color + 'Primary'], backgroundColor: valueData?.color && variables[valueData.color + 'Background'] }}
                        >
                            <ManagementTableCell
                                cellKey={cellKey}
                                cellValue={valueData?.amount ?? 0}
                                cellSymbol={valueData?.symbol ?? CurrencieSymbols[dataProvider.filter.currency]}
                                showDetails={showDetails}
                                onActionPerformed={onActionPerformed}
                                cellNotification={valueData?.seen == false ? true : false}
                                onError={onError}
                                type={type}
                                editData={editData}
                            />
                        </TableCell>
                    );
                })
                }

            </TableRow>
            {((isCollapsible && open) || !isCollapsible) && (row.children && row.children.length > 0) && (
                <>
                    {row.children.map(childRow => (
                        <ManagementTableRow
                            key={childRow.key}
                            row={childRow}
                            headers={headers}
                            values={values}
                            level={level + 1}
                            onActionPerformed={onActionPerformed}
                            onError={onError}
                            type={type}
                            editData={editData}
                            loadMoreData={loadMoreData}
                        />
                    ))}
                </>
            )}
        </>
    );
}

function ManagementTableCell({ cellKey, cellValue, cellSymbol, showDetails, cellNotification, onActionPerformed, onError, type, editData }) {
    const { user } = useAuth();
    const theme = useTheme();
    const dataProvider = useDataProvider();
    const snackbar = useSnackbar();

    const [error, setError] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [value, setValue] = useState(cellValue ?? 0)

    function isNumber(value) {
        return /^-?\d*[\.,]?\d*$/.test(value);
    }

    const handleOpenMenu = (event) => {
        if (anchorEl == null && !isEditing)
            setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (cellValue !== value) {
            setValue(cellValue);
            setError(false);
        }
    }, [cellValue]);

    const handleChangeValue = (val) => {
        if (!isNumber(val))
            return setError(true);

        setError(false);
        setValue(val);
    };

    const handleExitEdit = (event, onKeyPress) => {
        if (value == "")
            setValue(0);

        event.stopPropagation();
        setIsEditing(false)
        if (onKeyPress)
            handleSave(value == "" ? 0 : value);
    }

    const handleSave = async (value) => {
        const result = await clientApi().data.store("data/sets/actuals", {
            "key": cellKey,
            "value": value,
            "currency": dataProvider.filter.currency,
            "environment": dataProvider.filter.environment,
            "granularity": dataProvider.filter.granularity,
            "overwrite": false,
            "checked": false
        }).catch((err) => {
            if (onError)
                onError(TableErrors.cell_save, err.code, cellKey, cellValue, value, cellSymbol);
            else
                snackbar.openError(err.code)
        });

        if (result) {
            snackbar.openSuccess("Value saved");
            const newFilter = { ...dataProvider.filter, path: `sets/transactions` };
            dataProvider.setFilter(newFilter);
        }
    }

    const disabled = !showDetails;

    return (
        <>
            <Box
                className="clickable-cell"
                sx={{
                    backgroundColor: (cellNotification) ? variables.orangeBackground : "inherit",
                    "&:hover": {
                        ".more-icon-button": {
                            display: "inherit"
                        }
                    },
                    cursor: (disabled) ? "not-alowed" : "pointer",
                }}
            >
                {!isEditing &&
                    <>
                        <Box onClick={() => editData && setIsEditing(true && !disabled)}>
                            <Tooltip title={cellNotification ? `You have unseen ${type} inside this category` : ""} placement="top">
                                <Typography>
                                    {cellSymbol}{parseFloat(value).toLocaleString('en-US')}
                                </Typography>
                            </Tooltip>
                        </Box>
                        {!disabled && (user?.isAdmin == true) &&
                            <IconButton
                                onClick={handleOpenMenu}
                                className='more-icon-button'
                                size='small'
                                sx={{
                                    position: "absolute",
                                    right: 0,
                                    display: "none"
                                }}
                            >
                                <MoreVert />
                            </IconButton>
                        }
                    </>
                }
                {isEditing && editData &&
                    <TextField
                        error={error}
                        sx={{
                            '& input[disabled]': {
                                '-webkit-text-fill-color': 'white',
                                'color': 'white'
                            },
                            '.MuiInputBase-root': {
                                borderRadius: 0,
                            }
                        }}
                        onBlur={handleExitEdit}
                        disabled={!isEditing}
                        size='small'
                        value={value}
                        onClick={() => setIsEditing(true)}
                        onChange={(e) => handleChangeValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleExitEdit(e, true);
                            }
                        }}
                        autoFocus
                    />
                }
            </Box>
            {(user?.isAdmin == true) &&
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    PaperProps={{
                        sx: {
                            backgroundColor: theme.palette.background.dark,
                        },
                    }}
                >
                    <MenuItem onClick={() => {
                        handleCloseMenu();
                        onActionPerformed?.({
                            type: "explore",
                            key: cellKey
                        })
                    }}>
                        <Typography variant='body2'>
                            Explore {type}
                        </Typography>
                    </MenuItem>
                </Menu>
            }
        </>
    )
}