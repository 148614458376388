import { useState } from 'react';
import { Box, Divider, Typography, MenuItem, Popover, } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useAuth } from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import variables from 'src/styles/variables.scss';
import { arrayToObject } from 'src/shared/utils';
import { useSelector } from 'react-redux';
import ComingSoon from 'src/components/comingSoon';

export default function AccountPopover() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const { user, logout } = useAuth();
  const globalSettings = arrayToObject(useSelector(state => state.settings));
  const [fullName, setFullName] = useState(globalSettings?.profile?.firstName + " " + globalSettings?.profile?.lastName);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setOpen(null);
  }

  return (
    <>
      <Typography
        onClick={handleOpen}
        sx={{
          p: 0,
          cursor: 'pointer',
          fontSize: '12px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            background: variables.orangePrimary,
            padding: '3px 4px',
            borderRadius: '2px',
            color: '#000',
            fontSize: '10px',
            fontWeight: '500',
            marginRight: '0.5rem',
            lineHeight: '1',
          }}>
          ADMIN
        </span>
        {fullName}
        <KeyboardArrowDownIcon />
      </Typography>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 200,
            background: variables.darkGraySecondary,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 0.5 }}>
          <Typography variant="subtitle2" sx={{ px: '1rem', }} noWrap>
            {fullName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', px: '1rem', }} noWrap>
            {user.attributes.email}
          </Typography>
        </Box>
        <Divider />
        <MenuItem onClick={() => handleNavigation("/profile-settings")}>
          Profile
        </MenuItem>
        <MenuItem onClick={() => handleNavigation("/security-settings")}>
          Security and privacy
        </MenuItem>
        <MenuItem disabled onClick={() => handleNavigation("/account/help")} sx={{ justifyContent: 'space-between' }}>
          Help & FAQ
          <ComingSoon></ComingSoon>
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout} sx={{ mb: '0.5rem', color: variables.redPrimary }}>
          Sign Out
        </MenuItem>
      </Popover>
    </>
  );
}
