import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Container, Divider, FormControl, InputLabel, MenuItem, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SettingTypes } from "src/shared/enums";
import { useDispatch, useSelector } from "react-redux";
import { GlobalActionTypes } from "src/redux/globals";
import { useAuth } from "src/hooks/useAuth";
import { arrayToObject } from "src/shared/utils";
import clientApi from "src/client/client";
import variables from 'src/styles/variables.scss';
import { useSnackbar } from "src/hooks/useSnackbar";

export default function AccountSettingsPage() {
    const [tab, setTab] = useState(0);

    return (
        <Container>
            <Stack direction={"row"} my={2} alignItems={"center"} spacing={3}>
                <Typography sx={{ fontSize: '14px', color: variables.lightGraySecondary, fontWeight: 600 }}>Profile</Typography>
            </Stack>
            <Tabs value={tab} sx={{ marginTop: 4, marginBottom: 4 }}>
                <Tab label="User Profile" onClick={() => setTab(0)} />
                <Tab label="Company Profile" onClick={() => setTab(1)} />
            </Tabs>
            {
                tab === 0 && <ProfileInformationTab></ProfileInformationTab>
            }
            {
                tab === 1 && <CompanyInformationTab></CompanyInformationTab>
            }
        </Container>
    )
}

const formatValue = (value) => {
    return value
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const roles = [
    { label: "CEO (Chief Executive Officer)", value: "CEO" },
    { label: "CFO (Chief Financial Officer)", value: "CFO" },
    { label: "COO (Chief Operating Officer)", value: "COO" },
    { label: "CTO (Chief Technology Officer)", value: "CTO" },
    { label: "CMO (Chief Marketing Officer)", value: "CMO" },
    { label: "CIO (Chief Information Officer)", value: "CIO" },
    { label: "CHRO (Chief Human Resources Officer)", value: "CHRO" },
    { label: "CDO (Chief Data Officer)", value: "CDO" },
    { label: "CSO (Chief Security Officer)", value: "CSO" },
    { label: "CLO (Chief Legal Officer)", value: "CLO" },
    { label: "VP of Financial Operations", value: "VP of Financial Operations"},
    { label: "Other", value: "Other" }
];

const employeeRanges = [
    { label: "1-10", value: "1-10" },
    { label: "11-50", value: "11-50" },
    { label: "51-200", value: "51-200" },
    { label: "201-500", value: "201-500" },
    { label: "501-1000", value: "501-1000" },
    { label: "1001+", value: "1001+" }
];

const industries = [
    { label: "Agriculture, Forestry & Fishing", value: "Agriculture, Forestry & Fishing" },
    { label: "Mining & Extractives", value: "Mining & Extractives" },
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "Construction", value: "Construction" },
    { label: "Utilities", value: "Utilities" },
    { label: "Retail & Wholesale", value: "Retail & Wholesale" },
    { label: "Transport & Storage", value: "Transport & Storage" },
    { label: "Accommodation & Food", value: "Accommodation & Food" },
    { label: "IT & Communications", value: "IT & Communications" },
    { label: "Finance & Insurance", value: "Finance & Insurance" },
    { label: "Real Estate", value: "Real Estate" },
    { label: "Professional & Technical", value: "Professional & Technical" },
    { label: "Business Administration & Support", value: "Business Administration & Support" },
    { label: "Public Administration & Defence", value: "Public Administration & Defence" },
    { label: "Education", value: "Education" },
    { label: "Health & Social Care", value: "Health & Social Care" },
    { label: "Arts, Entertainment & Recreation", value: "Arts, Entertainment & Recreation" },
    { label: "Other Services", value: "Other Services" }
];

function ProfileInformationTab() {
    const dispatch = useDispatch();
    const snackbar = useSnackbar();
    const user = useAuth();
    const globalSettings = arrayToObject(useSelector(state => state.settings));
    const [dirty, setDirty] = useState(false);
    const [loading, setLoading] = useState(false);
    const [generalError, setGeneralError] = useState('');
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        role: '',
    });
    const [profileInfo, setProfileInfo] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        role: '',
    });

    useEffect(() => {
        const fetchProfileInfo = () => {
            const fetchedProfileInfo = globalSettings?.profile;
            setProfileInfo({
                firstName: fetchedProfileInfo?.firstName || '',
                lastName: fetchedProfileInfo?.lastName || '',
                phoneNumber: fetchedProfileInfo?.phoneNumber || '',
                role: fetchedProfileInfo?.role || '',
            });
        };
        fetchProfileInfo();
    }, []);

    const validateFirstName = (name) => /^[A-Za-z\s]{1,35}$/.test(name) && name.trim() !== '';
    const validateName = (name) => name === '' || /^[A-Za-z\s]{1,35}$/.test(name);
    const validatePhoneNumber = (number) => number === '' || /^[+]?[\d\s]*$/.test(number);

    const handleInputChange = (field) => (event) => {
        const { value } = event.target;
        let error = '';

        switch (field) {
            case 'firstName':
                if (!validateFirstName(value)) {
                    error = 'First name is required, must contain only characters and max length is 35.';
                }
                break;
            case 'lastName': // Allow lastName to be empty
                if (!validateName(value) && value !== '') {
                    error = 'Only characters are allowed, and max length is 35.';
                }
                break;
            case 'phoneNumber':
                if (!validatePhoneNumber(value)) {
                    error = 'Invalid phone number. Only digits and + are allowed.';
                }
                break;
        }

        setProfileInfo({ ...profileInfo, [field]: value });
        setErrors({ ...errors, [field]: error });
        setDirty(true);
        setGeneralError('');
    };

    const handleSubmitChange = async () => {
        if (Object.values(errors).some((error) => error)) {
            setGeneralError('Please correct the errors in the form.');
            return;
        }

        const formattedProfileInfo = {
            ...profileInfo,
            firstName: formatValue(profileInfo.firstName),
            lastName: formatValue(profileInfo.lastName),
            // No change needed for phoneNumber and address
        };

        setLoading(true);
        try {
            await clientApi().app.settings.set(SettingTypes.profile, formattedProfileInfo);
            dispatch({ type: GlobalActionTypes.USER_SETTINGS, payload: [{ "name": SettingTypes.profile, "value": formattedProfileInfo }] });
            setDirty(false);
            setProfileInfo(formattedProfileInfo);
            snackbar.openSuccess(`Profile updated successfully!`);

        } catch (error) {
            setGeneralError('An error occurred while updating your profile. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container sx={{ padding: 0, margin: 0 }}>
            <Stack direction={"row"} mb={3}>
                {/* <Box sx={{ width: "100%" }}>
                    <Typography variant="h6">Profile information</Typography>
                    <Typography variant="body2">Update your profile information</Typography>
                </Box> */}
                {/* <UserBadge sx={{ marginLeft: "auto" }} ></UserBadge> */}
            </Stack>
            <Stack spacing={3}>
                <TextField
                    required={true}
                    value={profileInfo.firstName}
                    onChange={handleInputChange('firstName')}
                    label="First Name"
                    placeholder="Enter your first name"
                    helperText={errors.firstName}
                    error={!!errors.firstName}
                />
                <TextField
                    value={profileInfo.lastName}
                    onChange={handleInputChange('lastName')}
                    label="Last Name"
                    placeholder="Enter your last name"
                    helperText={errors.lastName}
                    error={!!errors.lastName}
                />
                <TextField
                    value={profileInfo.phoneNumber}
                    onChange={handleInputChange('phoneNumber')}
                    label="Telephone Number"
                    placeholder="Enter your phone number"
                    helperText={errors.phoneNumber}
                    error={!!errors.phoneNumber}
                />
                <Typography sx={{ fontSize: '14px', color: variables.lightGraySecondary, mt: '0 !important', px: '0.5rem' }}>Optional</Typography>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Your Role</InputLabel>
                    <Select
                        value={profileInfo.role}
                        onChange={handleInputChange('role')}
                        label="Your Role"
                    >
                        <MenuItem value="">
                            <em>Select Role</em>
                        </MenuItem>
                        {roles.map((role) => (
                            <MenuItem key={role.value} value={role.value}>
                                {role.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    value={user?.user?.attributes?.email}
                    disabled
                    label="Email"
                    placeholder="Enter your email address"
                    sx={{
                        '.MuiFormLabel-root': {
                            color: variables.lightGraySecondary + ' !important',
                        },
                        '.MuiInputBase-input': {
                            '-webkit-text-fill-color': variables.lightGraySecondary + ' !important',
                            cursor: 'not-allowed',
                        }
                    }}
                />
                <LoadingButton
                    fullWidth
                    size="large"
                    variant={"outlined"}
                    disabled={!dirty || loading || Object.values(errors).some((error) => error)}
                    loading={loading}
                    onClick={handleSubmitChange}
                >
                    Submit changes
                </LoadingButton>
                {generalError && (
                    <Typography color="error" variant="body2">
                        {generalError}
                    </Typography>
                )}
            </Stack>
        </Container>
    );
}

function UserBadge({ sx }) {
    const { user } = useAuth();
    const globalSettings = arrayToObject(useSelector(state => state.settings));

    const profileInfo = globalSettings?.profile;
    const fullName = [profileInfo.firstName, profileInfo.lastName].join(" ");
    const _url = "https://ui-avatars.com/api/?name=";

    return (
        <Box sx={{ sx }}>
            <Avatar src={`${_url}${fullName}`} alt="photoURL" style={{ width: 50, height: 50 }} />
        </Box>
    )
}

function CompanyInformationTab() {
    const dispatch = useDispatch();
    const snackbar = useSnackbar();
    const globalSettings = arrayToObject(useSelector(state => state.settings));
    const [dirty, setDirty] = useState(false);
    const [loading, setLoading] = useState(false);
    const [generalError, setGeneralError] = useState('');
    const [errors, setErrors] = useState({
        companyName: '',
        industry: '',
        noOfEmployees: '',
        contactPersonName: '',
        contactEmail: '',
        contactPersonRole: '',
        contactPhone: '',
    });

    const [companyInfo, setCompanyInfo] = useState({
        companyName: '',
        industry: '',
        noOfEmployees: '',
        contactPersonName: '',
        contactEmail: '',
        contactPersonRole: '',
        contactPhone: '',
    });

    useEffect(() => {
        const fetchCompanyInfo = () => {
            const fetchedCompanyInfo = globalSettings?.company_info;
            setCompanyInfo({
                companyName: fetchedCompanyInfo?.companyName || '',
                industry: fetchedCompanyInfo?.industry || '',
                noOfEmployees: fetchedCompanyInfo?.noOfEmployees || '',
                contactPersonName: fetchedCompanyInfo?.contactPersonName || '',
                contactEmail: fetchedCompanyInfo?.contactEmail || '',
                contactPersonRole: fetchedCompanyInfo?.contactPersonRole || '',
                contactPhone: fetchedCompanyInfo?.contactPhone || '',
            });
        };
        fetchCompanyInfo();
    }, []);

    // Example onChange handler for text fields
    const handleInputChange = (field) => (event) => {
        const { value } = event.target;
        let error = '';

        setCompanyInfo({ ...companyInfo, [field]: value });
        setErrors({ ...errors, [field]: error });
        setDirty(true);
        setGeneralError('');
    };

    const handleSubmitChange = async () => {
        if (Object.values(errors).some((error) => error)) {
            setGeneralError('Please correct the errors in the form.');
            return;
        }

        const formattedCompanyInfo = {
            ...companyInfo,
        };

        setLoading(true);
        try {
            await clientApi().app.settings.set(SettingTypes.company_info, formattedCompanyInfo);
            dispatch({ type: GlobalActionTypes.USER_SETTINGS, payload: [{ "name": SettingTypes.company_info, "value": formattedCompanyInfo }] });
            setDirty(false);
            setCompanyInfo(formattedCompanyInfo);
            snackbar.openSuccess(`Company profile updated successfully!`);

        } catch (error) {
            setGeneralError('An error occurred while updating your profile. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container sx={{ padding: 0, margin: 0 }}>
            <Stack spacing={3}>
                <TextField
                    value={companyInfo.companyName}
                    onChange={handleInputChange('companyName')}
                    label="Company Name"
                    placeholder="Enter Company Name"
                    helperText={errors.companyName}
                    error={!!errors.companyName}
                />
                <FormControl fullWidth>
                    <InputLabel id="industry-label">Industry</InputLabel>
                    <Select
                        value={companyInfo.industry}
                        onChange={handleInputChange('industry')}
                        label="Industry"
                    >
                        <MenuItem value="">
                            <em>Select Industry</em>
                        </MenuItem>
                        {industries.map((industry) => (
                            <MenuItem key={industry.value} value={industry.value}>
                                {industry.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="employees-label">Number of Employees</InputLabel>
                    <Select
                        value={companyInfo.noOfEmployees}
                        onChange={handleInputChange('noOfEmployees')}
                        label="Number of Employees"
                    >
                        <MenuItem value="">
                            <em>Select Number of Employees</em>
                        </MenuItem>
                        {employeeRanges.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Divider></Divider>
                <TextField
                    value={companyInfo.contactPersonName}
                    onChange={handleInputChange('contactPersonName')}
                    label="Contact Person Full Name"
                    placeholder="Enter Contact Person Full Name"
                    helperText={errors.contactPersonName}
                    error={!!errors.contactPersonName}
                />
                <TextField
                    value={companyInfo.contactEmail}
                    onChange={handleInputChange('contactEmail')}
                    label="Contact Email"
                    placeholder="Enter Contact Email"
                    helperText={errors.contactEmail}
                    error={!!errors.contactEmail}
                />
                <FormControl fullWidth>
                    <InputLabel id="employees-label">Contact Person Role</InputLabel>
                    <Select
                        value={companyInfo.contactPersonRole}
                        onChange={handleInputChange('contactPersonRole')}
                        label="Contact Person Role"
                    >
                        <MenuItem value="">
                            <em>Select Contact Person Role</em>
                        </MenuItem>
                        {roles.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    value={companyInfo.contactPhone}
                    onChange={handleInputChange('contactPhone')}
                    label="Contact Telephone Number"
                    placeholder="Enter Contact Telephone Number"
                    helperText={errors.contactPhone}
                    error={!!errors.contactPhone}
                />
                <Typography sx={{ fontSize: '14px', color: variables.lightGraySecondary, mt: '0 !important', px: '0.5rem' }}>Optional</Typography>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant={"outlined"}
                    disabled={!dirty || loading || Object.values(errors).some((error) => error)}
                    loading={loading}
                    onClick={handleSubmitChange}
                >
                    Submit changes
                </LoadingButton>
                {generalError && (
                    <Typography color="error" variant="body2">
                        {generalError}
                    </Typography>
                )}
            </Stack>
        </Container>
    )
}