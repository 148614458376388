import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer } from '@mui/material';
import NavSectionMobile from '../../../components/nav-section/NavSectionMobile';
import { userNavConfig, adminNavConfig } from './config';
import variables from 'src/styles/variables.scss';
import useResponsive from 'src/hooks/useResponsive';
import NavSection from 'src/components/nav-section/NavSection';
import { useAuth } from 'src/hooks/useAuth';

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { user } = useAuth();
  const [expandNav, setExpandNav] = useState(false);

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const NAV_WIDTH = isDesktop ? (expandNav ? 178 : 72) : 178;

  const navConfig = (user?.isAdmin || user?.isDemo) ? adminNavConfig : userNavConfig;

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }

    if (expandNav) {
      setExpandNav(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Box
      sx={{
        height: 1,
        backgroundColor: variables.darkGraySecondary,
      }}
    >
      {isDesktop ? <NavSection data={navConfig} expandNav={expandNav} setExpandNav={setExpandNav} /> : <NavSectionMobile data={navConfig} />}
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <>
          {!expandNav &&
            <>
              <Box sx={{ height: '100%' }}>
                {renderContent}
              </Box>
            </>
          }
          {expandNav &&
            <>
              <Drawer
                open={expandNav}
                onClose={() => setExpandNav(false)}
                ModalProps={{
                  keepMounted: true,
                }}
                PaperProps={{
                  sx: { width: 178 },
                }}
              >
                {renderContent}
              </Drawer>
            </>
          }
        </>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
